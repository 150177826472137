import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IconButtonProps } from '@nexds/web'
import { useTheme } from 'styled-components'

import { ScreenElementsStateMutator } from '@/presentation/modules/MapScreen/states/ScreenElementsState'

import { Container, ToolPanelHeader, ToolPanel, ToolPanelBody, ToolPanelTitle, ToolButton } from './Toolbar.styles'

export interface ToolProps {
  key: string
  title: string
  icon: IconButtonProps['icon']
  content?: React.JSX.Element
  onPress?: () => void
}

interface ToolbarProps {
  tools: ToolProps[]
  shouldHide: boolean
  activeToolKey: string
  setActiveToolKey: (activeTool: string) => void
  onOtherItemPressCallbacks: (() => void)[]
}

function Toolbar(props: ToolbarProps) {
  const { tools, shouldHide, activeToolKey, setActiveToolKey, onOtherItemPressCallbacks } = props

  const toolbarRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  const [showToolPanel, setShowToolPanel] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!toolbarRef.current?.contains(event.target as Node) && activeToolKey !== null) {
        handleCloseToolPanel()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [activeToolKey])

  const setActiveToolKeyPreserveAnimation = (animationDuration: number, activeTool: string) => {
    setTimeout(() => {
      setActiveToolKey(activeTool)
    }, animationDuration)
  }

  const ActiveTool = useMemo(() => {
    const activeTool = tools.find((tool) => tool.key === activeToolKey)
    const content = activeTool?.content
    const title = activeTool?.title

    return {
      title,
      Content: content ? () => content : null
    }
  }, [activeToolKey, tools])

  function handleItemCLick(itemKey: string) {
    const toolPressed = tools.find((tool) => tool.key === itemKey)

    if (!toolPressed?.content) {
      setShowToolPanel(false)
    }
    if (toolPressed?.onPress) {
      toolPressed.onPress()
      return
    }

    if (activeToolKey) {
      if (activeToolKey === itemKey) {
        setShowToolPanel((prevState) => !prevState)
        setActiveToolKeyPreserveAnimation(100, null)
      } else {
        onOtherItemPressCallbacks.forEach((cb) => cb())
        setActiveToolKey(itemKey)
        setShowToolPanel(true)
      }
    } else {
      setActiveToolKey(itemKey)
      setShowToolPanel(true)
    }
  }

  const handleCloseToolPanel = useCallback(() => {
    setShowToolPanel(false)
    setActiveToolKeyPreserveAnimation(100, null)
  }, [])

  useEffect(() => {
    if (showToolPanel) {
      ScreenElementsStateMutator.setToolPanel(true)
    } else {
      ScreenElementsStateMutator.setToolPanel(false)
    }
  }, [showToolPanel])

  return (
    <Container ref={toolbarRef} $should_hide={shouldHide}>
      {tools.map((tool) => (
        <Fragment key={tool.key}>
          <ToolButton
            label={tool.title}
            onClick={() => handleItemCLick(tool.key)}
            color="ghost"
            iconColor={activeToolKey === tool.key ? theme.colors.primaryL2 : theme.colors.neutralL5}
            size="xs"
            icon={tool.icon}
            $is_active={tool.key === activeToolKey}
          />
          {!!ActiveTool.Content && (
            <ToolPanel $show={showToolPanel}>
              <ToolPanelHeader>
                <ToolPanelTitle>{ActiveTool.title}</ToolPanelTitle>
              </ToolPanelHeader>
              <ToolPanelBody>
                <ActiveTool.Content />
              </ToolPanelBody>
            </ToolPanel>
          )}
        </Fragment>
      ))}
    </Container>
  )
}

export { Toolbar }
