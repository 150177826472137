import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  position: relative;

  width: calc(100% + 9px);
  padding: 18px 0;
  padding-right: 9px;
  height: calc(100% - 20px); // Container padding
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
  }
`

export const ResultsContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: calc(76px + 24px);
  width: calc(100% - 9px);

  background: ${({ theme }) => theme.colors.secondary};

  border-radius: 8px;

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryL2};
  }

  max-height: 240px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
  }
`

interface ADHPResultProps {
  isSelected: boolean
}

export const ADHPResult = styled.div<ADHPResultProps>`
  padding: 8px 12px;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  gap: 4px;

  transition: background 100ms;

  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.secondaryL1 : 'transparent')};

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryL1};
  }
`

export const ADHPCode = styled.h1`
  ${({ theme }) => theme.fonts.infoMD};
`

export const ADHPName = styled.h2`
  ${({ theme }) => theme.fonts.infoSM};
  color: ${({ theme }) => theme.colors.neutralL2};

  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
`

export const NoADHPSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100% - 94px);
`

export const NoADHPSelected = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondaryL3};
  text-align: center;
`

export const NoChartsForSelectedADHPContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
`

export const NoChartsForSelectedADHP = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondaryL3};
  text-align: center;
`

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  height: calc(90% - 94px);
`

export const SelectedADHPContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const SelectedADHPCode = styled.h1`
  ${({ theme }) => theme.fonts.infoLG};
`

export const SelectedADHPName = styled.h2`
  ${({ theme }) => theme.fonts.infoMD};
`

export const ChartPDF = styled.iframe`
  width: 100%;

  margin: 0 auto;

  aspect-ratio: 1/1.414;

  border: none;
`

export const ChartImg = styled.img`
  width: 100%;

  margin: 0 auto;

  aspect-ratio: 1/1.414;
`

export const ChartButtons = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;

  gap: 16px;

  width: 100%;
`

export const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.secondary};

  cursor: pointer;

  transition: background 100ms;

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryL1};
  }
`

export const PlaceOverMapButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;

  border-radius: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.secondary};

  cursor: pointer;

  transition: background 100ms;

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryL1};
  }
`
