import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { InjectionTokens } from '@/controller/tokens'

import { CoordWaypoint } from '@/domain/models'

import { IUserWaypointsRepository } from '@/data/UserWaypointsRepository/IUserWaypointsRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useI18n } from '@/presentation/hooks/useI18n'
import { ModalUtil } from '@/utils/modalUtil'

import { MapImperativeInterface } from '../../MapScreen/interfaces/MapImperativeInterface'
import { UserWaypointManagementScreen } from '../components/UserWaypointManagementScreen/UserWaypointManagementScreen'
import { UserWaypointsScreen } from '../components/UserWaypointsScreen/UserWaypointsScreen'

function UserWaypointsScreenPresenter() {
  const location = useLocation()
  const navigate = useNavigate()

  const { t } = useI18n()

  const userWaypointsRepository = useInjection<IUserWaypointsRepository>(InjectionTokens.UserWaypointsRepository)

  const [userWaypoints, setUserWaypoints] = useState<CoordWaypoint[]>([])
  const [searchInputValue, setSearchInputValue] = useState('')

  const getUserWaypoints = useCallback(() => {
    const result = userWaypointsRepository.getWaypoints()

    if (result.isSuccess) {
      setUserWaypoints(result.getValue())
    }
  }, [userWaypointsRepository])

  useEffect(() => {
    getUserWaypoints()
  }, [])

  const filteredWaypoints = useMemo(() => {
    if (searchInputValue === '') return userWaypoints
    return userWaypoints.filter((item) => item.customName.toLowerCase().includes(searchInputValue.toLowerCase()))
  }, [userWaypoints, searchInputValue])

  const handleCreateUserWaypoint = useCallback(
    async (waypoint: CoordWaypoint) => {
      await userWaypointsRepository.saveWaypoint(waypoint)
      getUserWaypoints()
    },
    [userWaypointsRepository]
  )

  const handleDeleteUserWaypoint = useCallback(
    async (id: string) => {
      ModalUtil.drawer.show({
        onPositive: async () => {
          await userWaypointsRepository.deleteWaypoint(id)
          navigate(-1)
          getUserWaypoints()
        },
        onNegative: () => {},
        positiveColor: 'danger',
        positiveLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE'),
        negativeLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_GO-BACK'),
        title: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TITLE'),
        headerDescription: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TEXT')
      })
    },
    [userWaypointsRepository]
  )

  const handleUpdateUserWaypoint = useCallback(
    async (waypoint: CoordWaypoint, waypointId: string) => {
      const result = await userWaypointsRepository.updateWaypoint(waypoint, waypointId) // TODO: Trocar para um useCase

      if (result.isSuccess) {
        getUserWaypoints()
      }
    },
    [userWaypointsRepository]
  )

  const handleShowUserWaypointOnMap = useCallback((waypoint: CoordWaypoint) => {
    MapImperativeInterface.flyToCoordinates(waypoint.coordinates, 11, 2000)
  }, [])

  const handleBatchDeleteWaypoints = useCallback(
    async (waypoints: CoordWaypoint[]) => {
      ModalUtil.drawer.show({
        onPositive: async () => {
          await userWaypointsRepository.deleteWaypoints(waypoints) // TODO: Trocar para um useCase
          getUserWaypoints()
        },
        onNegative: () => {},
        positiveColor: 'danger',
        positiveLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE'),
        negativeLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_GO-BACK'),
        title: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TITLE'),
        headerDescription: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TEXT')
      })
    },
    [userWaypointsRepository]
  )

  const handleExportWaypoints = useCallback(
    async (waypoints: CoordWaypoint[]) => {
      const result = await userWaypointsRepository.exportWaypoints(waypoints) // TODO: Trocar para um useCase

      if (result.isSuccess) {
        const url = window.URL.createObjectURL(new Blob([result.getValue()]))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
          .toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })
          .replace(/\//g, '-')
          .replace(',', '')
          .replace(/:/g, '-')

        link.setAttribute('download', `waypoints-${date}.csv`)
        document.body.appendChild(link)
        link.click()
      }
    },
    [userWaypointsRepository]
  )

  const handleImportWaypoints = useCallback(
    async (file: File) => {
      const result = await userWaypointsRepository.importWaypoints(file) // TODO: Trocar para um useCase

      if (result.isSuccess) {
        getUserWaypoints()
      }
    },
    [userWaypointsRepository]
  )

  return (
    <>
      {location.pathname === '/settings/user-waypoints' ? (
        <UserWaypointsScreen
          waypoints={filteredWaypoints}
          handleBatchDeleteWaypoints={handleBatchDeleteWaypoints}
          handleExportWaypoints={handleExportWaypoints}
          handleImportWaypoints={handleImportWaypoints}
          setSearchInputValue={setSearchInputValue}
          searchInputValue={searchInputValue}
        />
      ) : location.pathname === '/settings/user-waypoints/create' ? (
        <UserWaypointManagementScreen
          action="create"
          handleCreateUserWaypoint={handleCreateUserWaypoint}
          handleDeleteUserWaypoint={handleDeleteUserWaypoint}
          handleShowUserWaypointOnMap={handleShowUserWaypointOnMap}
          handleUpdateUserWaypoint={handleUpdateUserWaypoint}
        />
      ) : (
        location.pathname.startsWith('/settings/user-waypoints/edit') && (
          <UserWaypointManagementScreen
            action="update"
            handleCreateUserWaypoint={handleCreateUserWaypoint}
            handleDeleteUserWaypoint={handleDeleteUserWaypoint}
            handleShowUserWaypointOnMap={handleShowUserWaypointOnMap}
            handleUpdateUserWaypoint={handleUpdateUserWaypoint}
          />
        )
      )}
    </>
  )
}

export { UserWaypointsScreenPresenter }
