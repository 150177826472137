import { useState, useCallback, useEffect } from 'react'
import type React from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { ADHP } from '@/domain/models'
import { ProcedureChart, ProcedureChartFileType, ProcedureChartInfo } from '@/domain/protocols/ProcedureChart'
import { ProcedureChartState } from '@/domain/states/ProcedureChartState'

import { AeroInfoRepository } from '@/data/AeroInfoRepository/AeroInfoRepository'
import { ManifestItem } from '@/data/ProcedureChartsRepository/IProcedureChartsRepository'
import { ProcedureChartsRepository } from '@/data/ProcedureChartsRepository/ProcedureChartsRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'

import { DrawerImperativeInterface } from '../Base/containers/DrawerPresenter/DrawerImperativeInterface'
import { MapImperativeInterface } from '../MapScreen/interfaces/MapImperativeInterface'
import { ChartScreen } from './components/ChartScreen/ChartScreen'

function ChartScreenPresenter() {
  const [adhpSearchValue, setAdhpSearchValue] = useState('')
  const [adhpSearchResults, setAdhpSearchResults] = useState<ADHP[]>([])
  const [selectedAdhp, setSelectedAdhp] = useState<ADHP>(null)
  const [selectedChartType, setSelectedChartType] = useState<ProcedureChart>(null)
  const [selectedChart, setSelectedChart] = useState<string>(null)
  const [selectedChartFileType, setSelectedChartFileType] = useState<ProcedureChartFileType>(null)
  const [adhpManifest, setAdhpManifest] = useState<ManifestItem[]>(null)

  const aeroinfoRespository = useInjection<AeroInfoRepository>(InjectionTokens.AeroInfoRepository)
  const procedureChartsRepository = useInjection<ProcedureChartsRepository>(InjectionTokens.ProcedureChartsRepository)
  const procedureChartState = useInjection<ProcedureChartState>(InjectionTokens.ProcedureChartState)

  const handleAdhpSearchChange = useCallback<React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>((e) => {
    setAdhpSearchValue(e.target.value)
    setSelectedAdhp(null)
    setSelectedChartType(null)
  }, [])

  const handleAdhpResultClick = useCallback((adhp: ADHP) => {
    setSelectedAdhp(adhp)
    setAdhpSearchValue(adhp.code)
  }, [])

  const handleSelectChart = useCallback((chart: string, type: ProcedureChartFileType) => {
    setSelectedChart(chart)
    setSelectedChartFileType(type)
  }, [])

  const handleSelectChartType = useCallback((chartType: string) => {
    setSelectedChart(null)
    setSelectedChartFileType(null)
    setSelectedChartType(null)

    setSelectedChartType(chartType as ProcedureChart)
  }, [])

  const handlePlaceOverMap = useCallback(async () => {
    const chart = adhpManifest?.find((item) => item.file === selectedChart)

    const uri = `${procedureChartsRepository.getBaseUrl()}/${selectedAdhp.code}/${chart.type}/${chart.imageFile}`

    const newProcedureChartState: ProcedureChartInfo = {
      name: chart.name,
      uri: uri,
      boundaries: chart.boundaries
    }

    procedureChartState.setProcedureChart(newProcedureChartState)

    const bounds = {
      southwestLng: chart.boundaries[3][1],
      southwestLat: chart.boundaries[3][0],
      northeastLng: chart.boundaries[1][1],
      northeastLat: chart.boundaries[1][0]
    }

    MapImperativeInterface.flyToBounds(
      [bounds.southwestLng, bounds.southwestLat, bounds.northeastLng, bounds.northeastLat],
      [100, 100, 100, 100],
      1000
    )

    DrawerImperativeInterface.close()
  }, [selectedChart, adhpManifest])

  const fetchADHPResults = useCallback(async () => {
    const results = await aeroinfoRespository.searchByInfo(adhpSearchValue, 15, true)
    setAdhpSearchResults(results as ADHP[])
  }, [aeroinfoRespository, adhpSearchValue])

  const fetchProcedureCharts = useCallback(async () => {
    const result = await procedureChartsRepository.getProcedureChartsManifest(selectedAdhp.code)

    if (result.isSuccess) {
      setAdhpManifest(result.getValue())
      return
    }

    setAdhpManifest([])
    setSelectedChart(null)
    setSelectedChartFileType(null)
    setSelectedChartType(null)
  }, [procedureChartsRepository, selectedAdhp])

  useEffect(() => {
    fetchADHPResults()

    if (selectedAdhp) {
      fetchProcedureCharts()
    }
  }, [adhpSearchValue, selectedAdhp])

  return (
    <ChartScreen
      adhpManifestBaseUrl={procedureChartsRepository.getBaseUrl()}
      adhpSearchResults={adhpSearchResults}
      adhpSearchValue={adhpSearchValue}
      selectedAdhp={selectedAdhp}
      adhpManifest={adhpManifest}
      selectedChartType={selectedChartType}
      selectedChart={selectedChart}
      selectedChartFileType={selectedChartFileType}
      handleSelectChart={handleSelectChart}
      handleAdhpSearchChange={handleAdhpSearchChange}
      handleAdhpResultClick={handleAdhpResultClick}
      handlePlaceOverMap={handlePlaceOverMap}
      handleSelectChartType={handleSelectChartType}
    />
  )
}

export { ChartScreenPresenter }
