import { useLayoutEffect } from 'react'

import GeoJSON from 'geojson'
import { MapLayerMouseEvent, CircleLayout, CirclePaint } from 'mapbox-gl'

import { useMapContext } from './MapContext'

interface MapCircleLayerProps {
  id: string
  sourceId: string
  color?: CirclePaint['circle-color']
  radius?: CirclePaint['circle-radius']
  opacity?: CirclePaint['circle-opacity']
  strokeWidth?: CirclePaint['circle-stroke-width']
  strokeColor?: CirclePaint['circle-stroke-color']
  visibility?: CircleLayout['visibility']
  paint?: CirclePaint
  layout?: CircleLayout
  beforeLayerId?: string
  minZoomLevel?: number
  onCircleClick?: (features: GeoJSON.Feature[]) => void
}

function MapCircleLayer(props: MapCircleLayerProps): null {
  const {
    id,
    paint,
    color,
    radius,
    layout,
    opacity,
    sourceId,
    visibility,
    strokeColor,
    strokeWidth,
    minZoomLevel,
    beforeLayerId,
    onCircleClick
  } = props

  const { map } = useMapContext()

  useLayoutEffect(() => {
    if (!map) return

    map.addLayer(
      {
        id,
        type: 'circle',
        source: sourceId,
        layout: {
          visibility: visibility ?? 'visible',
          ...layout
        },
        paint: {
          'circle-color': color,
          'circle-radius': radius,
          'circle-opacity': opacity ?? 1,
          'circle-stroke-color': strokeColor ?? '#000',
          'circle-stroke-width': strokeWidth ?? 0,
          ...paint
        },
        minzoom: minZoomLevel ?? 0
      },
      beforeLayerId
    )

    const symbolClickListener = (event: MapLayerMouseEvent) => {
      onCircleClick?.(event.features ?? [])
    }

    if (onCircleClick) {
      map.on('click', id, symbolClickListener)
    }

    const mouseEnterListener = () => {
      map.getCanvas().style.cursor = 'pointer'
    }

    const mouseLeaveListener = () => {
      map.getCanvas().style.cursor = ''
    }

    map.on('mouseenter', id, mouseEnterListener)
    map.on('mouseleave', id, mouseLeaveListener)

    return () => {
      if (onCircleClick) {
        map.off('click', id, symbolClickListener)
      }

      map.off('mouseenter', id, mouseEnterListener)
      map.off('mouseleave', id, mouseLeaveListener)
      map.getCanvas().style.cursor = ''

      if (map.getLayer(id)) {
        map.removeLayer(id)
      }
    }
  }, [map])

  return null
}

export { MapCircleLayer }
