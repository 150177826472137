import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TextField } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { Waypoint } from '@/domain/models'
import { AddWaypointToRouteUseCase, OverrideWaypointOnRoute } from '@/domain/useCases/Route'

import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { BackdropImperativeInterface } from '@/presentation/interfaces/BackdropImperativeInterface'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { appZIndex } from '@/presentation/utils/zIndexMapper'

import { MapImperativeInterface } from '../../MapScreen/interfaces/MapImperativeInterface'
import { MapSearchState, MapSearchStateProps } from '../../MapScreen/states/MapSearchState'
import { ScreenElementsState, ScreenElementsStateProps } from '../../MapScreen/states/ScreenElementsState'
import { getFitBoundaries } from '../../MapScreen/utils/map'
import { SearchList } from '../components/SearchList'
import { SearchBox, SearchContainer } from './WrittenSearchPresenter.styles'

interface WrittenSearchPresenterProps {
  shouldOverlap: boolean
}

function WrittenSearchPresenter(props: WrittenSearchPresenterProps) {
  const { shouldOverlap } = props
  const { t } = useI18n()
  const navigate = useNavigate()

  const mapSearchState = useBehaviorSubject<MapSearchStateProps>(MapSearchState)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const aeroInfoRepository = useInjection<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState<Waypoint[]>([] as Waypoint[])

  const searchWaypoints = useCallback(
    async (value: string) => {
      const resultSet = await aeroInfoRepository.searchByInfo(value, 100)
      setSearchResults(resultSet)
    },
    [aeroInfoRepository]
  )

  const handleSearchInputValueChanged = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value)

    searchWaypoints(e.target.value)
  }, [])

  const handleFocus = useCallback(() => {
    searchWaypoints(searchValue)

    sendMetrics('PLANNINGAPP_SEARCH_INPUT_CLICKED')
  }, [searchValue])

  const handleAddWaypointToRoute = useCallback(
    (item: Waypoint): void => {
      if (mapSearchState.searchOverride !== null) {
        new OverrideWaypointOnRoute().execute({ waypoint: item, index: mapSearchState.searchOverride })
      } else {
        const updatedRoute = new AddWaypointToRouteUseCase().execute(item)
        if (updatedRoute.isFailure) return

        const padding: [number, number, number, number] = [
          40 + screenElementsState.boundsDefaultPadding.top,
          75 + screenElementsState.boundsDefaultPadding.right,
          10 + screenElementsState.boundsDefaultPadding.bottom,
          75 + screenElementsState.boundsDefaultPadding.left
        ]

        if (updatedRoute.getValue().waypoints.length === 1) {
          navigate(RouterPaths.ROUTES_PANEL)
          MapImperativeInterface.flyToCoordinates(item.coordinates, 11, 2000)
        } else {
          const boundaries = getFitBoundaries(updatedRoute.getValue())
          MapImperativeInterface.flyToBounds(boundaries, padding, 500)
        }
      }

      setSearchValue('')
      setSearchResults([])
    },
    [mapSearchState.searchOverride, screenElementsState.boundsDefaultPadding]
  )

  useEffect(() => {
    if (searchResults.length > 0)
      BackdropImperativeInterface.show(shouldOverlap ? appZIndex.SEARCH_BOX : appZIndex.DRAWER - 1, 0.3, () =>
        setSearchResults([])
      )
    else BackdropImperativeInterface.hide()
  }, [searchResults.length])
  return (
    <SearchContainer>
      <SearchBox shouldOverlap={shouldOverlap}>
        <TextField
          labelGutter={false}
          helpGutter={false}
          placeholder={t('GLOBAL_SEARCH-BOX_PLACEHOLDER')}
          size="sm"
          isLightStyle
          clearable={searchValue.length > 0}
          value={searchValue}
          onFocus={handleFocus}
          onChange={handleSearchInputValueChanged}
        />
        {searchResults.length > 0 && (
          <SearchList waypoints={searchResults} maxHeight={400} onWaypointClick={handleAddWaypointToRoute} />
        )}
      </SearchBox>
    </SearchContainer>
  )
}

export { WrittenSearchPresenter }
