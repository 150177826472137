import { useCallback, useEffect, useMemo, useState } from 'react'

import { ProcedureChart } from '@/domain/protocols/ProcedureChart'

import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ChartSelector, ChartSelectorItem, Container, Label } from './ChartSelect.styles'

interface ChartSelectProps {
  availableCharts: ProcedureChart[]
  defaultSelectedChart?: ProcedureChart
  onSelectChart: (chart: ProcedureChart) => void
}

function ChartSelect(props: ChartSelectProps) {
  const { availableCharts, onSelectChart, defaultSelectedChart } = props

  const { t } = useI18n()

  const [selectedChart, setSelectedChart] = useState<ProcedureChart | null>(null)

  const procedureCharts = useMemo(() => {
    const allProcedureCharts: ProcedureChart[] = ['ADC', 'PDC', 'SID', 'STAR', 'IAC', 'VAC', 'AOC', 'GMC']
    const filteredProcedureCharts = allProcedureCharts.map((chart) => {
      return {
        chart,
        disabled: !availableCharts.includes(chart as ProcedureChart)
      }
    })

    return filteredProcedureCharts
  }, [availableCharts])

  const handleChartClick = useCallback(
    (chart: { chart: ProcedureChart; disabled: boolean }) => {
      if (!chart.disabled) {
        onSelectChart(chart.chart)
        setSelectedChart(chart.chart)

        sendMetrics('PLANNINGAPP_DRAWER_CHARTS-TYPE_SELECTED', { type: chart.chart })
      }
    },
    [onSelectChart]
  )

  useEffect(() => {
    setSelectedChart(defaultSelectedChart)
    onSelectChart(defaultSelectedChart)
  }, [defaultSelectedChart])

  return (
    <Container>
      <Label htmlFor="procedure-charts">{t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-TYPE-LABEL').toUpperCase()}</Label>
      <ChartSelector id="procedure-charts">
        {procedureCharts.map((chart) => (
          <ChartSelectorItem
            isSelected={selectedChart === chart.chart}
            key={chart.chart}
            onClick={() => handleChartClick(chart)}
            disabled={chart.disabled}
          >
            {chart.chart}
          </ChartSelectorItem>
        ))}
      </ChartSelector>
    </Container>
  )
}

export { ChartSelect }
