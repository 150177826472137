import { useCallback, useEffect, useMemo } from 'react'

import { ConfirmModal } from '@nexds/web'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { drawerModalSubject, globalModalSubject, ModalLocale, ModalUtil } from '@/utils/modalUtil'

import { Backdrop, Center, ModalContainer } from './ModalPresenter.styles'

interface ModalPresenterProps {
  locale: ModalLocale
}

function ModalPresenter(props: ModalPresenterProps) {
  const { locale } = props

  const { t } = useI18n()

  const modalList = useBehaviorSubject(locale === 'drawer' ? drawerModalSubject : globalModalSubject)
  const currentModal = modalList[0]

  const hideModal = ModalUtil[locale].hide

  const defaultPositiveLabel = useMemo(() => t('MODAL_BUTTON_DEFAULT_CONFIRM'), [])
  const defaultNegativeLabel = useMemo(() => t('MODAL_BUTTON_DEFAULT_CANCEL'), [])

  const handleClose = useCallback(() => {
    hideModal(currentModal.id)
    if (currentModal.onClose) currentModal.onClose()
  }, [currentModal])

  if (modalList.length === 0) return null
  return (
    <ModalContainer>
      <Backdrop onClick={handleClose} />
      <Center>
        <ConfirmModal
          isOpen={modalList.length > 0}
          title={currentModal.title}
          description={currentModal.headerDescription}
          hasDescription={!!currentModal.headerDescription}
          positiveLabel={currentModal.positiveLabel ?? defaultPositiveLabel}
          negativeLabel={currentModal.negativeLabel ?? defaultNegativeLabel}
          positiveColor={currentModal.positiveColor}
          buttonsSize="xs"
          maxWidth={currentModal.maxWidth}
          onPositive={() => {
            hideModal(currentModal.id)
            if (currentModal.onPositive) {
              currentModal.onPositive()
            }
          }}
          onNegative={() => {
            hideModal(currentModal.id)
            currentModal.onNegative?.()
          }}
          onClose={handleClose}
        >
          {currentModal.content}
        </ConfirmModal>
      </Center>
    </ModalContainer>
  )
}

export { ModalPresenter }
