import { Assets } from '@nexds/web'
import styled from 'styled-components'

function BackToOldButton() {
  return (
    <Container href="https://app.nexatlas.com">
      <Assets.ChevronLeft size={16} color="#FCFCFC" />
      Voltar à versão anterior
    </Container>
  )
}

export { BackToOldButton }

const Container = styled.a`
  position: absolute;
  right: 9px;
  top: 10px;
  z-index: 10;
  border: none;
  cursor: pointer;
  text-decoration: none;
  height: 43px;
  padding: 14px 19px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};
  ${({ theme }) => theme.fonts.titleXS}
`
