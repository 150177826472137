import { useMemo } from 'react'

import GeoJSON from 'geojson'
import { useTheme } from 'styled-components'

import { Waypoint } from '@/domain/models'

import { stackIds } from '../../containers/MapStackPresenter'
import { MapCircleLayer } from '../MapView/MapCircleLayer'
import { useMapContext } from '../MapView/MapContext'
import { MapGeoJsonLayer } from '../MapView/MapGeoJsonLayer'
import { MapGeoJsonSource } from '../MapView/MapGeoJsonSource'
import { MapSymbolLayer } from '../MapView/MapSymbolLayer'
import {
  makeGeoJsonLineFeatureCollection,
  makeGeoJsonLineLabelFeatureCollection,
  makeGeoJsonPointFeatureCollection
} from './GeojsonUtils'
import {
  routeLineLabelLayout,
  routeLineLabelDefaultPaint,
  routeLineLeftBorderPaint,
  routeLinePaint,
  routeLineRightBorderPaint,
  routeWaypointCirclePaint,
  routeWaypointLabelLayout,
  routeWaypointLabelPaint
} from './MapRoute.styles'

interface MapRouteProps {
  waypoints: Waypoint[]
  nextWaypointSequence: number
}

function MapRoute(props: MapRouteProps) {
  const { waypoints, nextWaypointSequence } = props
  const theme = useTheme()

  const geoJsonPointFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonPointFeatureCollection(waypoints, nextWaypointSequence),
    [waypoints, nextWaypointSequence, makeGeoJsonPointFeatureCollection]
  )

  const geoJsonLineFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonLineFeatureCollection(waypoints, nextWaypointSequence),
    [waypoints, nextWaypointSequence, makeGeoJsonLineFeatureCollection]
  )

  const geoJsonLineLabelFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonLineLabelFeatureCollection(waypoints, nextWaypointSequence),
    [waypoints, nextWaypointSequence, makeGeoJsonLineLabelFeatureCollection]
  )

  const ids = {
    source: { waypoint: 'route-waypoints-source', line: 'route-line-source', lineLabel: 'route-line-label-source' },
    layer: {
      waypoint: { label: 'route-waypoint-label', point: 'route-waypoints-point' },
      line: {
        fill: 'route-line-fill',
        right: 'route-line-right-border',
        left: 'route-line-left-border',
        lineLabel: 'route-line-label'
      }
    }
  }

  return (
    <>
      <MapGeoJsonSource id={ids.source.waypoint} data={geoJsonPointFeatureCollection}>
        {/* Waypoint Label */}
        <MapSymbolLayer
          id={ids.layer.waypoint.label}
          sourceId={ids.source.waypoint}
          imageSrc={routeWaypointLabelLayout()['icon-image'] as string}
          layout={routeWaypointLabelLayout()}
          paint={routeWaypointLabelPaint(theme)}
          filter={['!=', 'code', '']}
          beforeLayerId={stackIds.route.pointLabel}
        />

        {/* Waypoint Circle */}
        <MapCircleLayer
          id={ids.layer.waypoint.point}
          sourceId={ids.source.waypoint}
          paint={routeWaypointCirclePaint(theme)}
          beforeLayerId={stackIds.route.point}
        />
      </MapGeoJsonSource>

      {/* Route Line */}
      <MapGeoJsonSource id={ids.source.line} data={geoJsonLineFeatureCollection}>
        <MapGeoJsonLayer
          id={ids.layer.line.fill}
          sourceId={ids.source.line}
          types={['line']}
          paint={routeLinePaint(theme)}
          beforeLayerId={stackIds.route.line}
        />
        <MapGeoJsonLayer
          id={ids.layer.line.right}
          sourceId={ids.source.line}
          types={['line']}
          paint={routeLineRightBorderPaint(theme)}
          beforeLayerId={stackIds.route.line}
        />
        <MapGeoJsonLayer
          id={ids.layer.line.left}
          sourceId={ids.source.line}
          types={['line']}
          paint={routeLineLeftBorderPaint(theme)}
          beforeLayerId={stackIds.route.line}
        />
      </MapGeoJsonSource>

      {/* Route Line Label */}
      <MapGeoJsonSource id={ids.source.lineLabel} data={geoJsonLineLabelFeatureCollection}>
        <MapSymbolLayer
          id={ids.layer.line.lineLabel}
          sourceId={ids.source.lineLabel}
          imageSrc={routeLineLabelLayout()['icon-image'] as string}
          layout={routeLineLabelLayout()}
          paint={routeLineLabelDefaultPaint(theme)}
          filter={['==', 'state', 'default']}
          beforeLayerId={stackIds.route.lineLabel}
        />
      </MapGeoJsonSource>
    </>
  )
}

export { MapRoute }
