import appStoreLogo from '@assets/app-store-logo.png'
import logoNexAtlasMobileLight from '@assets/nexatlas-mobile-light.svg'
import playStoreLogo from '@assets/play-store-logo.png'
import toMobileQRCode from '@assets/qrcode.svg'
import fiveStars from '@assets/star-app.svg'

import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import {
  AppDescription,
  AppDescriptionText,
  Body,
  Container,
  QRCodeContainer,
  QRCodeDescription,
  QRCodeStepsList,
  Rating,
  RatingContent,
  RatingDescription,
  RatingTitle,
  RatingTitleWrapper,
  StoreLinkButton,
  StoresButtonsWrapper,
  Title
} from './MobileAppScreen.styles'

function MobileAppScreen() {
  const { t } = useI18n()

  return (
    <Container>
      <Title>{t('DRAWER_MOBILE_MAIN_TITLE')}</Title>
      <Body>
        <Rating>
          <img src={logoNexAtlasMobileLight} />
          <RatingContent>
            <RatingTitleWrapper>
              <RatingTitle>{t('DRAWER_MOBILE_MAIN_APP-NAME')}</RatingTitle>
              <img src={fiveStars} />
            </RatingTitleWrapper>
            <RatingDescription>{t('DRAWER_MOBILE_MAIN_APP-INFO')}</RatingDescription>
          </RatingContent>
        </Rating>
        <AppDescription>
          <AppDescriptionText>{t('DRAWER_MOBILE_MAIN_APP_DESCRIPTION_PAR-1')}</AppDescriptionText>
          <AppDescriptionText>{t('DRAWER_MOBILE_MAIN_APP_DESCRIPTION_PAR-2')}</AppDescriptionText>
        </AppDescription>
        <StoresButtonsWrapper>
          <StoreLinkButton
            href="https://play.google.com/store/apps/details?id=com.nexatlas.pilotapp"
            target="_blank"
            onClick={() => sendMetrics('PLANNINGAPP_DRAWER_APP_CLICKED', { extra: 'play-store' })}
          >
            <img src={playStoreLogo} alt="play-store" />
          </StoreLinkButton>
          <StoreLinkButton
            href="https://apps.apple.com/us/app/nexatlas/id1562704198"
            target="_blank"
            onClick={() => sendMetrics('PLANNINGAPP_DRAWER_APP_CLICKED', { extra: 'app-store' })}
          >
            <img src={appStoreLogo} alt="app-store" />
          </StoreLinkButton>
        </StoresButtonsWrapper>
        <QRCodeContainer>
          <QRCodeDescription>{t('DRAWER_MOBILE_MAIN_QR-CODE_INSTRUCTION')}</QRCodeDescription>
          <img src={toMobileQRCode} alt="" />
          <QRCodeStepsList type="1">
            <QRCodeDescription>{t('DRAWER_MOBILE_MAIN_QR-CODE_INSTRUCTION_STEP-1')}</QRCodeDescription>
            <QRCodeDescription>{t('DRAWER_MOBILE_MAIN_QR-CODE_INSTRUCTION_STEP-2')}</QRCodeDescription>
            <QRCodeDescription>{t('DRAWER_MOBILE_MAIN_QR-CODE_INSTRUCTION_STEP-3')}</QRCodeDescription>
          </QRCodeStepsList>
        </QRCodeContainer>
      </Body>
    </Container>
  )
}

export { MobileAppScreen }
