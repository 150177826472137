import { jsonDateParser } from 'json-date-parser'

import { ProcedureChartInfo } from '../protocols/ProcedureChart'
import { SerializableState } from './SerializableState'

export type BaseChartType = 'WAC' | 'ENRCH' | 'ENRCL'
export type OverlayChartType = 'REA' | 'ARC'
export type Overlay2ChartType = 'REH'
export type BaseMapType = 'ROAD' | 'SAT' | 'TER'

export interface MapLayerStateProps {
  // Configs
  nightMode: boolean

  // Map
  baseMap: BaseMapType

  // Charts
  baseChart: BaseChartType
  overlayChart: OverlayChartType
  overlay2Chart: Overlay2ChartType

  // Airspace
  adiz: boolean
  afis: boolean
  fir: boolean
  firSector: boolean
  tma: boolean
  tmaSector: boolean
  ctr: boolean
  atz: boolean
  fiz: boolean
  fca: boolean
  suaDangerous: boolean
  suaProhibited: boolean
  suaRestricted: boolean

  // ADHP
  aerodromes: boolean
  helipads: boolean
  pavedRunway: boolean
  nightOperation: boolean
  ats: boolean
  ifr: boolean
  minRunwayLength: number
  userWaypoints: boolean

  procedureChart: ProcedureChartInfo
}

const INITIAL_STATE: MapLayerStateProps = {
  nightMode: !(new Date().getHours() > 6 && new Date().getHours() < 18),

  // Map
  baseMap: 'ROAD',

  // Charts
  baseChart: 'WAC',
  overlayChart: null,
  overlay2Chart: null,

  // Airspace
  adiz: false,
  afis: false,
  fir: false,
  firSector: false,
  tma: false,
  tmaSector: false,
  ctr: false,
  atz: false,
  fiz: false,
  fca: false,
  suaDangerous: false,
  suaProhibited: false,
  suaRestricted: false,

  // ADHP
  aerodromes: true,
  helipads: false,
  pavedRunway: false,
  nightOperation: false,
  ats: false,
  ifr: false,
  minRunwayLength: 0,
  userWaypoints: false,

  // Procedure charts
  procedureChart: null
}

export class MapLayerState extends SerializableState<MapLayerStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setNightMode(nightMode: boolean) {
    this._updateStateProp('nightMode', nightMode)
  }

  setLayer(layer: keyof MapLayerStateProps, value: boolean) {
    this._updateStateProp(layer, value)
  }

  // Base map
  setBaseMap(baseMap: BaseMapType) {
    this._updateStateProp('baseMap', baseMap)
  }

  // Charts
  setBaseChart(baseChart: BaseChartType) {
    this._updateStateProp('baseChart', baseChart)
  }

  setOverlayChart(overlayChart: OverlayChartType) {
    this._updateStateProp('overlayChart', overlayChart)
  }

  setOverlay2Chart(overlay2Chart: Overlay2ChartType) {
    this._updateStateProp('overlay2Chart', overlay2Chart)
  }

  // Airspace
  setAdiz(adiz: boolean) {
    this._updateStateProp('adiz', adiz)
  }

  setAfis(afis: boolean) {
    this._updateStateProp('afis', afis)
  }

  setAtz(atz: boolean) {
    this._updateStateProp('atz', atz)
  }

  setCtr(ctr: boolean) {
    this._updateStateProp('ctr', ctr)
  }

  setFir(fir: boolean) {
    this._updateStateProp('fir', fir)
  }

  setFirSector(firSector: boolean) {
    this._updateStateProp('firSector', firSector)
  }

  setFiz(fiz: boolean) {
    this._updateStateProp('fiz', fiz)
  }

  setFca(fca: boolean) {
    this._updateStateProp('fca', fca)
  }

  setSuaDangerous(suaDangerous: boolean) {
    this._updateStateProp('suaDangerous', suaDangerous)
  }

  setSuaProhibited(suaProhibited: boolean) {
    this._updateStateProp('suaProhibited', suaProhibited)
  }

  setSuaRestricted(suaRestricted: boolean) {
    this._updateStateProp('suaRestricted', suaRestricted)
  }

  setTma(tma: boolean) {
    this._updateStateProp('tma', tma)
  }

  setTmaSector(tmaSector: boolean) {
    this._updateStateProp('tmaSector', tmaSector)
  }

  // Procedure chart

  getProcedureChart(): ProcedureChartInfo {
    return this.getStateSnapshot().procedureChart
  }

  isAirspaceActive(): boolean {
    const snapshot = this.getStateSnapshot()
    return (
      snapshot.adiz ||
      snapshot.afis ||
      snapshot.atz ||
      snapshot.ctr ||
      snapshot.fir ||
      snapshot.firSector ||
      snapshot.fiz ||
      snapshot.fca ||
      snapshot.suaDangerous ||
      snapshot.suaProhibited ||
      snapshot.suaRestricted ||
      snapshot.tma ||
      snapshot.tmaSector
    )
  }

  // ADHP
  setAerodromes(aerodromes: boolean) {
    this._updateStateProp('aerodromes', aerodromes)
  }

  setHelipads(helipads: boolean) {
    this._updateStateProp('helipads', helipads)
  }

  setPavedRunway(pavedRunway: boolean) {
    this._updateStateProp('pavedRunway', pavedRunway)
  }

  setNightOperation(nightOperation: boolean) {
    this._updateStateProp('nightOperation', nightOperation)
  }

  setAts(ats: boolean) {
    this._updateStateProp('ats', ats)
  }

  setIfr(ifr: boolean) {
    this._updateStateProp('ifr', ifr)
  }

  setMinRunwayLength(minRunwayLength: number) {
    this._updateStateProp('minRunwayLength', minRunwayLength)
  }

  setUserWaypoints(userWaypoints: boolean) {
    this._updateStateProp('userWaypoints', userWaypoints)
  }

  // Procedure charts
  setProcedureChart(procedureChart: ProcedureChartInfo) {
    this._updateStateProp('procedureChart', procedureChart)
  }

  getKey(): string {
    return 'mapLayer'
  }
}
