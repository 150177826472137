import GeoJSON from 'geojson'
import { injectable } from 'inversify'

import { AnyAirspace } from '@/domain/models/Airspace'
import { Result } from '@/domain/protocols/Result'

import { GenericVolatileRemoteRepository } from '../GenericVolatileRemoteRepository/GenericVolatileRemoteRepository'
import { AirspaceRepositoryData, AirspaceType, IAirspacesRepository } from './IAirspacesRepository'

@injectable()
class RemoteAirspaceRepository
  extends GenericVolatileRemoteRepository<AirspaceRepositoryData>
  implements IAirspacesRepository
{
  protected getRemoteUrl(): Record<string, string> {
    return {
      airspaces: `${import.meta.env.VITE_GEOSERVER_API_BASEURL}/cache/airspaces`
    }
  }

  protected getRepositoryId(): string {
    return 'airspaces'
  }

  protected getMaxAge(): number {
    return 2419200 // 28 days
  }

  public getAirspace(type: AirspaceType): Result<GeoJSON.FeatureCollection<GeoJSON.Polygon, AnyAirspace>> {
    const localData = this.getData('airspaces')

    if (localData.isSuccess) {
      if (!localData.getValue().hasOwnProperty(type)) {
        return Result.fail('Airspace not found')
      }

      return Result.ok(localData.getValue()[type] as GeoJSON.FeatureCollection<GeoJSON.Polygon, AnyAirspace>)
    }

    return Result.fail(localData.error ?? 'Airspace not found')
  }

  public getFeatureByCode(type: AirspaceType, code: string) {
    const airspace = this.getAirspace(type)

    if (airspace.isSuccess) {
      const feature = airspace.getValue().features.find((feature) => feature.properties?.code === code)

      if (feature) {
        return Result.ok(feature)
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

export { RemoteAirspaceRepository }
