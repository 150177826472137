import styled, { css, keyframes } from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

const fadeIn = keyframes`
  from {
    opacity: 0;
    filter: blur(2px);
    transform: translate3d(0, -50%, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(-10px, -50%, 0);
    visibility: visible;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(-10px, -50%, 0);
    visibility: visible;
  }
  to {
    opacity: 0;
    filter: blur(2px);
    transform: translate3d(0, -50%, 0);
    visibility: hidden;
  }
`

interface FeatureInfoContainerProps {
  show: boolean
}

export const FeatureInfoContainer = styled.div<FeatureInfoContainerProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: ${appZIndex.INFO_PANEL};

  max-height: 700px;
  overflow-y: hidden;

  width: 346px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};

  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};

  opacity: 0;

  ${({ show }) =>
    show
      ? css`
          animation: ${fadeIn} 0.3s forwards;
        `
      : css`
          animation: ${fadeOut} 0.1s forwards;
        `};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryL1};
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoMD};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`

export const Body = styled.div`
  padding: 24px;
  max-height: calc(90vh - 100px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

// Meteorology-tooltip
export const Content = styled.p`
  ${({ theme }) => theme.fonts.infoSM};
  color: ${({ theme }) => theme.colors.neutralL5};
  margin-bottom: 8px;
`

// Meteorology-legends
export const LegendsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const LegendContainer = styled.div`
  background-color: ${({ theme }) => theme?.colors?.secondary ?? '#000'};
  padding: 4px 12px;
  border-radius: 10px;
`

export const LegendTitle = styled.h1`
  ${({ theme }) => theme?.fonts?.titleSM ?? {}}
  padding: 8px 0;
  color: ${({ theme }) => theme?.colors?.neutralL4 ?? '#fff'};
`

export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`

export const LegendText = styled.p`
  ${({ theme }) => theme?.fonts?.infoLG ?? {}}
  margin-left: 12px;
  color: ${({ theme }) => theme?.colors?.neutralL4 ?? '#fff'};
  flex: 1;
`

interface LegendColorProps {
  color: string
}
export const LegendColor = styled.div<LegendColorProps>`
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
`

export const LegendImage = styled.img``

export const LegendTextIcon = styled.p`
  ${({ theme }) => theme?.fonts?.infoLG ?? {}}
  color: ${({ theme }) => theme?.colors?.neutralL4 ?? '#fff'};
  min-width: 36px;
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme?.colors?.secondaryL1 ?? '#000'};
`

// Airspaces
export const Section = styled.div`
  margin-bottom: 16px;
`

export const Subtitle = styled.h2`
  ${({ theme }) => theme.fonts.labelMD};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutralL5};
  margin-bottom: 24px;
`

interface LabelProps {
  $display?: 'block' | 'inline'
}

export const Label = styled.div<LabelProps>`
  ${({ theme }) => theme.fonts.infoMD};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutralL5};
  display: ${({ $display }) => $display || 'inline'};
`

export const Value = styled.div`
  ${({ theme }) => theme.fonts.textSM};
  line-height: 150%;
  color: ${({ theme }) => theme.colors.neutralL5};
  margin-top: 4px;
  white-space: pre-wrap;
`

export const Separator = styled.div`
  margin: 16px 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.secondaryL1};
`
