import { useEffect, useState } from 'react'

import chartARC from '@assets/chart-arc.png'
import chartENRCHigh from '@assets/chart-enrc_high.png'
import chartENRCLow from '@assets/chart-enrc_low.png'
import chartREA from '@assets/chart-rea.png'
import chartREH from '@assets/chart-reh.png'
import chartWAC from '@assets/chart-wac.png'
import mapRoad from '@assets/map-road.png'
import mapSatPlaces from '@assets/map-sat_places.png'
import mapSat from '@assets/map-sat.png'

import { InjectionTokens } from '@/controller/tokens'

import {
  BaseChartType,
  BaseMapType,
  MapLayerState,
  MapLayerStateProps,
  Overlay2ChartType,
  OverlayChartType
} from '@/domain/states/MapLayerState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ChartSelector } from '../ChartSelector'
import { ChartsContainer, SelectorsGrid } from '../ToolbarTools.styles'

function ChartsTool() {
  const [mapLayerState, mapLayerStateMutator] = useGlobalState<MapLayerState, MapLayerStateProps>(
    InjectionTokens.MapLayerState
  )

  const { t } = useI18n()

  const overlayCharts = [
    {
      key: 'REA',
      chartId: 'REA' as OverlayChartType,
      label: 'REA',
      image: chartREA,
      isActive: mapLayerState.overlayChart === 'REA'
    },
    {
      key: 'ARC',
      chartId: 'ARC' as OverlayChartType,
      label: 'ARC',
      image: chartARC,
      isActive: mapLayerState.overlayChart === 'ARC'
    }
  ]

  const overlay2Charts = [
    {
      key: 'REH',
      chartId: 'REH' as Overlay2ChartType,
      label: 'REH',
      image: chartREH,
      isActive: mapLayerState.overlay2Chart === 'REH'
    }
  ]
  const baseCharts = [
    {
      key: 'WAC',
      chartId: 'WAC' as BaseChartType,
      label: 'WAC',
      image: chartWAC,
      isActive: mapLayerState.baseChart === 'WAC'
    },
    {
      key: 'ENRCH',
      chartId: 'ENRCH' as BaseChartType,
      label: 'ENRC High',
      image: chartENRCHigh,
      isActive: mapLayerState.baseChart === 'ENRCH'
    },
    {
      key: 'ENRCL',
      chartId: 'ENRCL' as BaseChartType,
      label: 'ENRC Low',
      image: chartENRCLow,
      isActive: mapLayerState.baseChart === 'ENRCL'
    }
  ]

  const baseMaps = [
    {
      key: 'ROAD',
      chartId: 'ROAD' as BaseMapType,
      label: t('TOOLBAR_CHARTS-AND-MAPS_BASE-MAPS_ITEM_ROAD_LABEL'),
      image: mapRoad,
      isActive: mapLayerState.baseMap === 'ROAD'
    },
    {
      key: 'SAT',
      chartId: 'SAT' as BaseMapType,
      label: t('TOOLBAR_CHARTS-AND-MAPS_BASE-MAPS_ITEM_SATELLITE_LABEL'),
      image: mapSat,
      isActive: mapLayerState.baseMap === 'SAT'
    },
    {
      key: 'TER',
      chartId: 'TER' as BaseMapType,
      label: t('TOOLBAR_CHARTS-AND-MAPS_BASE-MAPS_ITEM_TERRAIN_LABEL'),
      image: mapSatPlaces,
      isActive: mapLayerState.baseMap === 'TER'
    }
  ]

  function handleOverlayChartClick(chartId: OverlayChartType) {
    const previousState = mapLayerState.overlayChart

    const newState: OverlayChartType = mapLayerState.overlayChart === chartId ? null : chartId

    mapLayerStateMutator.updateState({
      overlayChart: newState
    })

    if (previousState && previousState !== newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState,
        active: false
      })
    }

    if (newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: newState,
        active: true
      })
    }
  }

  function handleOverlay2ChartClick(chartId: Overlay2ChartType) {
    const previousState = mapLayerState.overlay2Chart

    const newState: Overlay2ChartType = mapLayerState.overlay2Chart === chartId ? null : chartId

    mapLayerStateMutator.updateState({
      overlay2Chart: newState
    })

    if (previousState && previousState !== newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState,
        active: false
      })
    }

    if (newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: newState,
        active: true
      })
    }
  }

  function handleBaseChartClick(chartId: BaseChartType) {
    const previousState = mapLayerState.baseChart

    const newState: BaseChartType = mapLayerState.baseChart === chartId ? null : chartId

    mapLayerStateMutator.updateState({
      baseChart: newState
    })

    if (previousState && previousState !== newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState,
        active: false
      })
    }

    if (newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: newState,
        active: true
      })
    }
  }

  function handleBaseMapClick(baseMapId: BaseMapType) {
    const previousState = { ...mapLayerState }

    const newState = mapLayerState.baseMap === baseMapId ? 'ROAD' : baseMapId

    mapLayerStateMutator.updateState({
      baseMap: newState,
      overlayChart: null,
      overlay2Chart: null,
      baseChart: null
    })

    if (previousState.overlayChart) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState.overlayChart,
        active: false
      })
    }

    if (previousState.overlay2Chart) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState.overlay2Chart,
        active: false
      })
    }

    if (previousState.baseChart) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState.baseChart,
        active: false
      })
    }

    if (previousState.baseMap && previousState.baseMap !== newState) {
      sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
        extra: previousState.baseMap,
        active: false
      })
    }

    sendMetrics('PLANNINGAPP_TOOLBAR_CHARTS-MAP_SELECTED', {
      extra: newState,
      active: true
    })
  }

  return (
    <ChartsContainer>
      <SelectorsGrid>
        {overlayCharts.map((overlayChart) => (
          <ChartSelector
            key={overlayChart.key}
            id={overlayChart.key}
            isActive={overlayChart.isActive}
            imageSrc={overlayChart.image}
            label={overlayChart.label}
            onChange={() => handleOverlayChartClick(overlayChart.chartId)}
          />
        ))}
        {overlay2Charts.map((overlay2Chart) => (
          <ChartSelector
            key={overlay2Chart.key}
            id={overlay2Chart.key}
            isActive={overlay2Chart.isActive}
            imageSrc={overlay2Chart.image}
            label={overlay2Chart.label}
            onChange={() => handleOverlay2ChartClick(overlay2Chart.chartId)}
          />
        ))}
        {baseCharts.map((baseChart) => (
          <ChartSelector
            key={baseChart.key}
            id={baseChart.key}
            isActive={baseChart.isActive}
            imageSrc={baseChart.image}
            label={baseChart.label}
            onChange={() => handleBaseChartClick(baseChart.chartId)}
          />
        ))}
        {baseMaps.map((baseMap) => (
          <ChartSelector
            key={baseMap.key}
            id={baseMap.key}
            isActive={baseMap.isActive}
            imageSrc={baseMap.image}
            label={baseMap.label}
            onChange={() => handleBaseMapClick(baseMap.chartId)}
          />
        ))}
      </SelectorsGrid>
    </ChartsContainer>
  )
}

export { ChartsTool }
