import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  width: 100%;
  padding: 20px 13px;
`

export const Body = styled.div`
  width: calc(100% + 9px);
  padding: 20px 13px;
  height: 90%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

export const Divider = styled.hr`
  margin: 30px 0 30px;
`

export const SectionTitle = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  text-transform: uppercase;
`

interface InfoTextProps {
  variant?: 'default' | 'warning'
}
export const InfoText = styled.p<InfoTextProps>`
  ${({ theme }) => theme.fonts.textMD}
  color: ${({ theme, variant }) => (variant === 'warning' ? theme.colors.error : theme.colors.neutralL5)};
`
export const LinkButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryL1};
  ${({ theme }) => theme.fonts.infoMD}
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.2s;
  }
`

export const LinkA = styled.a`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryL1};
  ${({ theme }) => theme.fonts.infoMD}
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.2s;
  }
`
