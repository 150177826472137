import type React from 'react'
import { KeyboardEventHandler, useMemo, useRef, useState } from 'react'

import { Download, PlotOverMap, Select, SelectItem, TextField } from '@nexds/web'
import { useTheme } from 'styled-components'

import { ADHP } from '@/domain/models'
import { ProcedureChart, ProcedureChartFileType } from '@/domain/protocols/ProcedureChart'

import { ADHPManifest } from '@/data/ProcedureChartsRepository/IProcedureChartsRepository'

import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ChartSelect } from '../ChartSelect/ChartSelect'
import {
  ADHPCode,
  ADHPName,
  ADHPResult,
  ChartButtons,
  ChartPDF,
  ChartsContainer,
  Container,
  Content,
  DownloadButton,
  NoADHPSelected,
  NoADHPSelectedContainer,
  NoChartsForSelectedADHP,
  NoChartsForSelectedADHPContainer,
  PlaceOverMapButton,
  ResultsContainer,
  SelectedADHPCode,
  SelectedADHPContainer,
  SelectedADHPName
} from './ChartScreen.styles'

interface ChartScreenProps {
  adhpManifestBaseUrl: string
  adhpManifest: ADHPManifest
  adhpSearchValue: string
  adhpSearchResults: ADHP[]
  selectedAdhp: ADHP
  selectedChart: string
  selectedChartType: ProcedureChart
  selectedChartFileType: ProcedureChartFileType
  handleSelectChart: (chart: string, type: ProcedureChartFileType) => void
  handlePlaceOverMap: () => void
  handleSelectChartType: (chartType: string) => void
  handleAdhpResultClick: (adhp: ADHP) => void
  handleAdhpSearchChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

function ChartScreen(props: ChartScreenProps) {
  const {
    adhpManifest,
    adhpSearchValue,
    adhpSearchResults,
    adhpManifestBaseUrl,
    selectedAdhp,
    selectedChart,
    selectedChartType,
    selectedChartFileType,
    handleSelectChart,
    handlePlaceOverMap,
    handleSelectChartType,
    handleAdhpResultClick,
    handleAdhpSearchChange
  } = props

  const { t } = useI18n()
  const theme = useTheme()

  const [currentAdhpResult, setCurrentAdhpResult] = useState(0)
  const selectedAdhpResult = adhpSearchResults[currentAdhpResult]
  const resultsRef = useRef<HTMLDivElement>(null)

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const resultItemHeight = 55

    if (e.key === 'Escape') {
      e.preventDefault()
      e.currentTarget.blur()
      setCurrentAdhpResult(0)
      handleAdhpResultClick(selectedAdhpResult)
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault()

      setCurrentAdhpResult((prev) => (prev < adhpSearchResults.length - 1 ? prev + 1 : adhpSearchResults.length - 1))

      if (currentAdhpResult > 2) {
        resultsRef.current.scrollBy({ top: resultItemHeight, behavior: 'smooth' })
      }
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()

      setCurrentAdhpResult((prev) => (prev >= 0 ? prev - 1 : 0))

      resultsRef.current.scrollBy({ top: -resultItemHeight, behavior: 'smooth' })
    }

    if (e.key === 'Enter' && selectedAdhpResult) {
      e.preventDefault()
      e.currentTarget.blur()
      handleAdhpResultClick(selectedAdhpResult)
    }
  }

  const filteredAdhpManifest = useMemo(() => {
    if (!selectedChartType || !adhpManifest) return []

    return adhpManifest.filter((item) => item.type === selectedChartType)
  }, [adhpManifest, selectedChartType])

  const availableCharts = useMemo(() => {
    if (!adhpManifest) return []

    return adhpManifest.map((item) => item.type)
  }, [adhpManifest])

  return (
    <Container>
      <Content>
        <TextField
          placeholder={t('DRAWER_PROCEDURE-CHARTS_SEARCH-AERODROME-PLACEHOLDER')}
          label={t('DRAWER_PROCEDURE-CHARTS_MAIN_TITLE')}
          rightIcon="Search"
          value={selectedAdhp ? '' : adhpSearchValue}
          onChange={(e) => {
            handleAdhpSearchChange(e)
            setCurrentAdhpResult(0)
          }}
          onKeyDown={handleSearchKeyDown}
        />
        {!selectedAdhp && (
          <ResultsContainer ref={resultsRef}>
            {adhpSearchResults.map((adhp) => {
              return (
                <ADHPResult
                  key={adhp.id.toString()}
                  onClick={() => handleAdhpResultClick(adhp)}
                  isSelected={selectedAdhpResult === adhp}
                >
                  <ADHPCode>{adhp.code}</ADHPCode>
                  <ADHPName>{adhp.name}</ADHPName>
                </ADHPResult>
              )
            })}
          </ResultsContainer>
        )}
        {selectedAdhp ? (
          <ChartsContainer>
            <SelectedADHPContainer>
              <SelectedADHPCode>{selectedAdhp.code}</SelectedADHPCode>
              <SelectedADHPName>{selectedAdhp.name}</SelectedADHPName>
            </SelectedADHPContainer>

            {availableCharts.length > 1 ? (
              <>
                <ChartSelect
                  availableCharts={availableCharts}
                  defaultSelectedChart={selectedChartType}
                  onSelectChart={(chart) => {
                    handleSelectChartType(chart)
                  }}
                />

                {selectedChartType && (
                  <Select
                    key={selectedChartType}
                    label={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-LABEL')}
                    onChange={(value: string) => {
                      const chart = adhpManifest?.find((item) => item.file === value)?.file
                      const type = adhpManifest?.find((item) => item.file === value)?.fileType
                      handleSelectChart(chart, type)
                    }}
                    disabled={!selectedChartType}
                    maxDropdownRows={7}
                    placeholder={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-PLACEHOLDER')}
                    helpGutter={false}
                  >
                    {filteredAdhpManifest?.map((item) => (
                      <SelectItem key={item.file} value={item.file} label={item.name} />
                    ))}
                  </Select>
                )}

                {selectedChartType && selectedChart && (
                  <ChartPDF
                    src={`${adhpManifestBaseUrl}/${selectedAdhp.code}/${selectedChartType}/${selectedChart}#toolbar=0&navpanes=0&view=FitH&scrollbar=0`}
                  ></ChartPDF>
                )}

                {/* {selectedChartType && selectedChart && selectedChartFileType === 'georeferenced' && (
                  <ChartImg
                    src={`${adhpManifestBaseUrl}/${selectedAdhp.code}/${selectedChartType}/${selectedChart}`}
                  ></ChartImg>
                )} */}

                {selectedChartType && selectedChart && (
                  <ChartButtons>
                    <DownloadButton
                      href={`${adhpManifestBaseUrl}/${selectedAdhp.code}/${selectedChartType}/${selectedChart}`}
                      title={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-DOWNLOAD')}
                      target="_blank"
                      onClick={() => {
                        sendMetrics('PLANNINGAPP_DRAWER_CHARTS-ACTION_CLICKED', { extra: 'download' })
                      }}
                    >
                      <Download size={'sm'} color={theme.colors.neutralL5} />
                    </DownloadButton>
                    {selectedChartFileType === 'georeferenced' && (
                      <PlaceOverMapButton
                        title={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-PLACE-OVER-MAP')}
                        onClick={() => {
                          sendMetrics('PLANNINGAPP_DRAWER_CHARTS-ACTION_CLICKED', { extra: 'plot-on-map' })
                          handlePlaceOverMap()
                        }}
                      >
                        <PlotOverMap size={'sm'} color={theme.colors.neutralL5} />
                      </PlaceOverMapButton>
                    )}
                  </ChartButtons>
                )}
              </>
            ) : (
              <NoChartsForSelectedADHPContainer>
                <NoChartsForSelectedADHP>
                  {t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-NO-CHARTS-FOR-ADHP')}
                </NoChartsForSelectedADHP>
              </NoChartsForSelectedADHPContainer>
            )}
          </ChartsContainer>
        ) : (
          <NoADHPSelectedContainer>
            <NoADHPSelected>{t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-NO-CHARTS')}</NoADHPSelected>
          </NoADHPSelectedContainer>
        )}
      </Content>
    </Container>
  )
}

export { ChartScreen }
