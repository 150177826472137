import * as Turf from '@turf/turf'
import GeoJSON from 'geojson'

import { ADHP, Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { calculateMagneticHeading, nmDistance } from '@/utils/coordinates'
import { limitString } from '@/utils/string'

export type WaypointStateType = 'default' | 'current' | 'past'

export type MapRouteWaypoint = {
  item: Waypoint
  state: WaypointStateType
}

export const MAP_SYMBOL_PRIORITIES = {
  CLOSE_CHART: 0,
  USER_LOCATION: 1,
  ADHP_HEADBOARD: 3,
  TARGET: 4,
  ROUTE_ADHP: 5,
  ROUTE_WAYPOINT: 10,
  ROUTE_LENGTH_HEADING: 15,
  MAP_ADHP: 20
}

const getWaypointState = (index: number, nextWaypointSequence: number): WaypointStateType => {
  if (!nextWaypointSequence) return 'default'

  const isPastItem = index + 1 < nextWaypointSequence
  const isCurrent = index + 1 === nextWaypointSequence

  if (isPastItem) {
    return 'past'
  } else if (isCurrent) {
    return 'current'
  } else {
    return 'default'
  }
}

export const makeGeoJsonPointFeatureCollection =
  (waypoints: Waypoint[], nextWaypointSequence: number) => (): GeoJSON.FeatureCollection => {
    const waypointToFeature = (waypoint: Waypoint, index: number): GeoJSON.Feature => {
      return {
        type: 'Feature',
        id: waypoint.id.toString(),
        properties: {
          code: limitString(waypoint.getDisplayName(), 16),
          state: getWaypointState(index + 1, nextWaypointSequence),
          isAdhp: waypoint instanceof ADHP,
          priority: waypoint instanceof ADHP ? MAP_SYMBOL_PRIORITIES.ROUTE_ADHP : MAP_SYMBOL_PRIORITIES.ROUTE_WAYPOINT
        },
        geometry: {
          type: 'Point',
          coordinates: waypoint.coordinates.toArray()
        }
      }
    }
    const pointFeatureArray: GeoJSON.Feature[] = waypoints.map(waypointToFeature)

    return {
      type: 'FeatureCollection',
      features: pointFeatureArray
    }
  }

export const makeGeoJsonLineFeatureCollection =
  (waypoints: Waypoint[], nextWaypointSequence: number) => (): GeoJSON.FeatureCollection => {
    const lineFeatureArray: GeoJSON.Feature[] = []
    for (let i = 1; i < waypoints.length; i++) {
      const currentWaypoint = waypoints[i]
      const pastWaypoint = waypoints[i - 1]

      // Create one geodesic point every 30nm
      const npoints = Math.ceil(nmDistance(pastWaypoint.coordinates, currentWaypoint.coordinates) / 30)

      lineFeatureArray.push({
        type: 'Feature',
        properties: {
          state: getWaypointState(i, nextWaypointSequence)
        },
        geometry: Turf.greatCircle(pastWaypoint.coordinates.toArray(), currentWaypoint.coordinates.toArray(), {
          npoints: npoints
        }).geometry
      })
    }

    return {
      type: 'FeatureCollection',
      features: lineFeatureArray
    }
  }

export const makeGeoJsonLineLabelFeatureCollection =
  (waypoints: Waypoint[], nextWaypointSequence: number) => (): GeoJSON.FeatureCollection => {
    const lineFeatureArray: GeoJSON.Feature[] = []
    for (let i = 1; i < waypoints.length; i++) {
      const currentWaypoint = waypoints[i]
      const pastWaypoint = waypoints[i - 1]

      let heading = Turf.rhumbBearing(pastWaypoint.coordinates.toArray(), currentWaypoint.coordinates.toArray()) - 90
      if (heading < 0) heading = Turf.bearingToAzimuth(heading)

      // Keep text upright on map
      let headingText = heading
      if (headingText > 90 && headingText < 270) headingText += 180

      const lineDistance = nmDistance(pastWaypoint.coordinates, currentWaypoint.coordinates)

      const lineHeading = calculateMagneticHeading(pastWaypoint.coordinates, currentWaypoint.coordinates)
      const headingLabel = Math.floor(lineHeading).toFixed(0).padStart(3, '0')
      const distanceLabel = lineDistance > 10 ? lineDistance.toFixed(0) : lineDistance.toFixed(1)

      lineFeatureArray.push({
        type: 'Feature',
        properties: {
          label: `${headingLabel}° ${distanceLabel}nm`,
          heading: heading,
          headingText: headingText,
          state: getWaypointState(i, nextWaypointSequence)
        },
        geometry: Turf.midpoint(pastWaypoint.coordinates.toArray(), currentWaypoint.coordinates.toArray()).geometry
      })
    }

    return {
      type: 'FeatureCollection',
      features: lineFeatureArray
    }
  }
