import { BehaviorSubject } from 'rxjs'

export interface ScreenElementsStateProps {
  drawer: boolean
  toolPanel: boolean
  meteorologyControl: boolean
  featureInfoPanel: boolean
  chat: boolean
  boundsDefaultPadding: {
    top: number
    right: number
    bottom: number
    left: number
  }
}

const elementDistance = {
  open: {
    top: 80,
    right: 375,
    bottom: 20 + 58 + 16 + 36,
    left: 360 + 16 + 50
  },
  close: {
    top: 0,
    right: 30 + 40,
    bottom: 0,
    left: 50 + 16
  }
}

const INITIAL_STATE: ScreenElementsStateProps = {
  drawer: false,
  toolPanel: false,
  meteorologyControl: false,
  featureInfoPanel: false,
  chat: false,
  boundsDefaultPadding: {
    top: elementDistance.close.top,
    right: elementDistance.close.right,
    bottom: elementDistance.close.bottom,
    left: elementDistance.close.left
  }
}

export const ScreenElementsState = new BehaviorSubject<ScreenElementsStateProps>(INITIAL_STATE)

export const ScreenElementsStateMutator = {
  mutate: (newState: ScreenElementsStateProps) => {
    ScreenElementsState.next(newState)
  },

  setDrawer: (drawer: boolean) => {
    if (ScreenElementsState.getValue().drawer === drawer) return
    ScreenElementsState.next({
      ...ScreenElementsState.getValue(),
      drawer,
      boundsDefaultPadding: {
        ...ScreenElementsState.getValue().boundsDefaultPadding,
        left: drawer ? elementDistance.open.left : elementDistance.close.left,
        top: drawer ? elementDistance.close.top : elementDistance.open.top
      }
    })
  },

  setToolPanel: (toolPanel: boolean) => {
    if (ScreenElementsState.getValue().toolPanel === toolPanel) return

    ScreenElementsState.next({
      ...ScreenElementsState.getValue(),
      toolPanel,
      boundsDefaultPadding: {
        ...ScreenElementsState.getValue().boundsDefaultPadding,
        right:
          toolPanel || ScreenElementsState.getValue().featureInfoPanel || ScreenElementsState.getValue().chat
            ? elementDistance.open.right
            : elementDistance.close.right
      }
    })
  },

  setFeatureInfoPanel: (featureInfoPanel: boolean) => {
    if (ScreenElementsState.getValue().featureInfoPanel === featureInfoPanel) return

    ScreenElementsState.next({
      ...ScreenElementsState.getValue(),
      featureInfoPanel,
      boundsDefaultPadding: {
        ...ScreenElementsState.getValue().boundsDefaultPadding,
        right:
          featureInfoPanel || ScreenElementsState.getValue().toolPanel || ScreenElementsState.getValue().chat
            ? elementDistance.open.right
            : elementDistance.close.right
      }
    })
  },

  setMeteorologyControl: (meteorologyControl: boolean) => {
    if (ScreenElementsState.getValue().meteorologyControl === meteorologyControl) return
    ScreenElementsState.next({
      ...ScreenElementsState.getValue(),
      meteorologyControl,
      boundsDefaultPadding: {
        ...ScreenElementsState.getValue().boundsDefaultPadding,
        bottom: meteorologyControl ? elementDistance.open.bottom : elementDistance.close.bottom
      }
    })
  },

  setChat: (chat: boolean) => {
    if (ScreenElementsState.getValue().chat === chat) return
    ScreenElementsState.next({
      ...ScreenElementsState.getValue(),
      chat,
      boundsDefaultPadding: {
        ...ScreenElementsState.getValue().boundsDefaultPadding,
        right:
          chat || ScreenElementsState.getValue().toolPanel || ScreenElementsState.getValue().featureInfoPanel
            ? elementDistance.open.right
            : elementDistance.close.right
      }
    })
  }
}
