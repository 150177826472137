import { useEffect, useState } from 'react'

import { Slider } from '@mui/material'
import { Assets, IconButton } from '@nexds/web'
import { useTheme } from 'styled-components'

import { useI18n } from '@/presentation/hooks/useI18n'
import { formatInUTC, timeSinceString } from '@/utils/date'

import {
  Container,
  InfoButton,
  InfoContainer,
  InfoText,
  PlayerContainer,
  SliderContainer
} from './MeteorologyControl.styles'

interface MeteorologyControlProps {
  frames: string[]
  selectedFrame: string
  infoExpired: boolean
  canOpenPlayer: boolean
  playerOpen: boolean
  canPlay: boolean
  playing: boolean
  hasLegend: boolean
  onOpenPlayer: () => void
  onPlayPause: () => void
  onFrameChange: (frame: string) => void
  onOpenLegend: () => void
}

function MeteorologyControl(props: MeteorologyControlProps) {
  const {
    frames,
    selectedFrame,
    infoExpired,
    canOpenPlayer,
    playerOpen,
    canPlay,
    playing,
    hasLegend,
    onOpenPlayer,
    onPlayPause,
    onFrameChange,
    onOpenLegend
  } = props

  const { t, getTimeFormat } = useI18n()
  const theme = useTheme()

  const [selectedFrameText, setSelectedFrameText] = useState('')

  useEffect(() => {
    const updateText = () => {
      const selectedFrameDate = new Date(+selectedFrame)
      const timeInUtc = formatInUTC(selectedFrameDate, getTimeFormat()) || '--'
      const now = new Date()

      if (selectedFrameDate < now) {
        const timeSince = timeSinceString(selectedFrameDate, now, t, true, true) || '--'
        setSelectedFrameText(
          t('METEOROLOGY_CONTROL_FRAME-TEXT_PAST', {
            timeInUtc,
            timeSince
          })
        )
      } else {
        const timeSince = timeSinceString(now, selectedFrameDate, t, true, true) || '--'
        setSelectedFrameText(
          t('METEOROLOGY_CONTROL_FRAME-TEXT_FUTURE', {
            timeInUtc,
            timeSince
          })
        )
      }
    }

    updateText()

    const textUpdateInterval = setInterval(() => {
      updateText()
    }, 10000)

    return () => clearInterval(textUpdateInterval)
  }, [selectedFrame])

  return (
    <Container show={playerOpen}>
      <InfoContainer infoWarning={infoExpired}>
        <InfoText>{selectedFrameText}</InfoText>
        {canOpenPlayer && (
          <InfoButton onClick={onOpenPlayer}>
            {playerOpen ? (
              <Assets.ChevronDown size="sm" color={theme.colors.neutralL5} />
            ) : (
              <Assets.ChevronUp size="sm" color={theme.colors.neutralL5} />
            )}
          </InfoButton>
        )}
      </InfoContainer>
      <PlayerContainer>
        {canPlay && <IconButton icon={playing ? 'Pause' : 'Play'} size="sm" color="ghost" onClick={onPlayPause} />}
        <SliderContainer>
          <Slider
            min={0}
            max={frames.length - 1}
            value={frames.indexOf(selectedFrame)}
            track="normal"
            step={1}
            onChange={(_, value) => onFrameChange(frames[value as number])}
            draggable
          />
        </SliderContainer>
        {hasLegend && <IconButton icon="TripleDot" size="sm" color="ghost" onClick={onOpenLegend} />}
      </PlayerContainer>
    </Container>
  )
}

export { MeteorologyControl }
