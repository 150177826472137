import { useMemo } from 'react'

import { rhumbDestination } from '@turf/turf'
import GeoJSON from 'geojson'
import { useTheme } from 'styled-components'

import { ADHP } from '@/domain/models'

import { fixHeadingByMagneticDeclination } from '@/utils/coordinates'

import { MapGeoJsonSource } from '../../components/MapView/MapGeoJsonSource'
import { MapSymbolLayer } from '../../components/MapView/MapSymbolLayer'
import { stackIds } from '../MapStackPresenter'
import { HeadboardIcon, trackHeadboardLayout, trackHeadboardPaint } from './TrackHeadboardPresenter.styles'

interface TrackHeadboardPresenterProps {
  adhps: ADHP[]
}

function TrackHeadboardPresenter(props: TrackHeadboardPresenterProps) {
  const { adhps } = props

  const theme = useTheme()

  const geoJsonFeature: GeoJSON.FeatureCollection = useMemo(() => {
    const features: GeoJSON.Feature[] = []
    adhps.forEach((adhp) => {
      if (!adhp?.rotaer?.runways) return
      adhp.rotaer.runways.forEach((runway) => {
        // Remove helipoint tracks
        if (runway.length < 100) return
        if (!runway?.thr) return

        runway.thr.forEach((thr) => {
          const thrNormalized = thr.ident.replace(/[^0-9]/g, '')

          const thrAlreadyExists = features.find(
            (feature) => feature.properties?.code === adhp.code && thrNormalized === feature.properties?.label
          )

          if (thrAlreadyExists) return

          features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: rhumbDestination(
                adhp.coordinates.toArray(),
                -(runway.length / 1000 + 1),
                fixHeadingByMagneticDeclination(Number(thrNormalized) * 10, adhp.coordinates, true)
              ).geometry.coordinates
            },
            properties: {
              label: thrNormalized,
              heading: fixHeadingByMagneticDeclination(Number(thrNormalized) * 10, adhp.coordinates, true),
              code: adhp.code
            }
          })
        })
      })
    })

    return {
      type: 'FeatureCollection',
      features
    }
  }, [adhps])

  const sourceId = 'track-headboard-source'
  const layerId = 'track-headboard-layer'

  return (
    <MapGeoJsonSource id={sourceId} data={geoJsonFeature}>
      <MapSymbolLayer
        id={layerId}
        sourceId={sourceId}
        beforeLayerId={stackIds.trackHeadboard}
        minZoomLevel={9}
        imageSrc={HeadboardIcon}
        layout={trackHeadboardLayout()}
        paint={trackHeadboardPaint(theme)}
      />
    </MapGeoJsonSource>
  )
}

export { TrackHeadboardPresenter }
