import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { meteorologyWarningTimes } from '@/domain/protocols/Meteorology'

import { IMeteorologyRepository, MeteorologyManifest } from '@/data/MeteorologyRepository/IMeteorologyRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import {
  MeteorologyState,
  MeteorologyStateMutator,
  MeteorologyStateProps
} from '@/presentation/modules/MapScreen/states/MeteorologyState'

import { FeatureInfoStateMutator, FeatureInfoState } from '../../MapScreen/states/FeatureInfoState'
import { ScreenElementsStateMutator } from '../../MapScreen/states/ScreenElementsState'
import { MeteorologyControl } from '../components/MeteorologyControl/MeteorologyControl'

function MeteorologyControlPresenter() {
  const meteorologyPackageRepository = useInjection<IMeteorologyRepository>(InjectionTokens.MeteorologyRepository)

  const meteorologyState = useBehaviorSubject<MeteorologyStateProps>(MeteorologyState)

  const featureInfoState = useBehaviorSubject(FeatureInfoState)

  const [frames, setFrames] = useState<string[]>(null)
  const [layerName, setLayerName] = useState<string>(null)
  const [legends, setLegends] = useState<MeteorologyManifest['legends']>(null)
  const [infoExpired, setInfoExpired] = useState<boolean>(false)
  const [playerOpen, setPlayerOpen] = useState(true)
  const [canPlay, setCanPlay] = useState<boolean>(false)
  const [playing, setPlaying] = useState(false)

  useLayoutEffect(() => {
    const cleanState = () => {
      setFrames(null)
      setLayerName(null)
      setLegends(null)
      setPlayerOpen(true)
      setCanPlay(false)
      setInfoExpired(false)
      MeteorologyStateMutator.setTime(null)
    }

    const run = async () => {
      if (meteorologyState.layer) {
        // TODO: Fazer o download do manifesto apenas se o layer mudar e se existir uma layer selecionada
        const meteorologyManifest = await meteorologyPackageRepository.getManifestByLayerName(meteorologyState.layer)

        if (meteorologyManifest.isFailure) {
          cleanState()
          return
        }

        const manifestValue = meteorologyManifest.getValue()

        const frames = manifestValue.frameTimestamps

        if (frames.length === 0) {
          cleanState()
          return
        }

        const legend = manifestValue.legends
        const timeDiff = Date.now() - Number(manifestValue.frameTimestamps[manifestValue.frameTimestamps.length - 1])

        setLayerName(manifestValue.humanReadableName)
        setFrames(frames)
        setLegends(legend && legend.length > 0 ? legend : null)
        setInfoExpired(timeDiff > meteorologyWarningTimes[meteorologyState.layer])
        setCanPlay(manifestValue.playable ?? true)

        if (manifestValue.currentFrameTimestamp) {
          MeteorologyStateMutator.setTime(manifestValue.currentFrameTimestamp)
        } else {
          MeteorologyStateMutator.setTime(frames[frames.length - 1])
        }

        if (frames.length <= 1) {
          setPlayerOpen(false)
        }
      } else {
        cleanState()
      }
    }

    run()
    setPlaying(false)
  }, [meteorologyState.layer])

  useEffect(() => {
    if (playing) {
      const interval = setInterval(() => {
        if (!frames) return

        const currentIndex = frames.indexOf(meteorologyState.time ?? '')
        const nextIndex = currentIndex + 1
        if (nextIndex < frames.length) {
          MeteorologyStateMutator.setTime(frames[nextIndex])
        } else {
          MeteorologyStateMutator.setTime(frames[0])
        }
      }, 500)

      return () => clearInterval(interval)
    }
  }, [playing, meteorologyState.time])

  const handleOpenPlayer = useCallback(() => {
    setPlayerOpen(!playerOpen)
    setPlaying(false)
  }, [playerOpen])

  const handlePlayPause = useCallback(async () => {
    setPlaying(!playing)
  }, [playing, meteorologyState.layer])

  const handleFrameChange = useCallback(
    (frame: string) => {
      if (frame === meteorologyState.time) return
      MeteorologyStateMutator.setTime(frame)
      setPlaying(false)
    },
    [meteorologyState.time]
  )

  const handleOpenLegend = useCallback(() => {
    if (featureInfoState.active?.type === 'meteorology-legend') {
      FeatureInfoStateMutator.hide()
      ScreenElementsStateMutator.setFeatureInfoPanel(false)
      return
    }
    FeatureInfoStateMutator.showMeteorologyLegend(layerName ?? '', legends ?? [])
    ScreenElementsStateMutator.setFeatureInfoPanel(true)
  }, [layerName, legends, featureInfoState.active?.type])

  if (!frames || !meteorologyState.layer || !meteorologyState.time) {
    ScreenElementsStateMutator.setMeteorologyControl(false)
    return null
  } else {
    ScreenElementsStateMutator.setMeteorologyControl(true)
    return (
      <MeteorologyControl
        frames={frames}
        selectedFrame={meteorologyState.time}
        infoExpired={infoExpired}
        canOpenPlayer={frames.length > 1}
        playerOpen={playerOpen}
        canPlay={canPlay}
        playing={playing}
        hasLegend={!!legends}
        onOpenPlayer={handleOpenPlayer}
        onPlayPause={handlePlayPause}
        onFrameChange={handleFrameChange}
        onOpenLegend={handleOpenLegend}
      />
    )
  }
}

export { MeteorologyControlPresenter }
