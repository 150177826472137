import { Assets } from '@nexds/web'
import GeoJSON from 'geojson'
import { useTheme } from 'styled-components'

import { useI18n } from '@/presentation/hooks/useI18n'

import { Body, CloseButton, Header, Label, Section, Title, Value } from './InfoPanel.styles'

interface AirspacesFeatureInfoProps {
  title?: string
  airspaceInfo: GeoJSON.GeoJsonProperties
  onClose?: () => void
}

function AirspacesFeatureInfo(props: AirspacesFeatureInfoProps) {
  const { title, airspaceInfo, onClose } = props

  const theme = useTheme()
  const { t, lang } = useI18n()

  const featureInSameLanguage = airspaceInfo?.localLangCode === lang()

  const chooseLanguageTxt = (txt?: string, txtEng?: string) => {
    if (featureInSameLanguage && txt) return txt
    if (!featureInSameLanguage && txtEng) return txtEng
    if (txtEng) return txtEng
    if (txt) return txt
    return null
  }

  const upperLimitTxt = chooseLanguageTxt(airspaceInfo?.upperLimitTxt, airspaceInfo?.upperLimitTxtEng)
  const lowerLimitTxt = chooseLanguageTxt(airspaceInfo?.lowerLimitTxt, airspaceInfo?.lowerLimitTxtEng)
  const oprHours = chooseLanguageTxt(airspaceInfo?.oprHours, airspaceInfo?.oprHoursEng)
  const rmk = chooseLanguageTxt(airspaceInfo?.rmk, airspaceInfo?.rmkEng)
  const commTxt = chooseLanguageTxt(airspaceInfo?.commTxt, airspaceInfo?.commTxtEng)
  const classTxt = chooseLanguageTxt(airspaceInfo?.class, airspaceInfo?.classEng)

  return (
    <>
      <Header>
        <Title>{t('AIRSPACES-INFO_MAIN_TITLE')}</Title>
        <CloseButton onClick={onClose && onClose}>
          <Assets.Close size={'md'} color={theme.colors.secondaryL3} />
        </CloseButton>
      </Header>
      <Body>
        <Section>
          <Label>{t('AIRSPACES-INFO_INFORMATION_SUBTITLE')}: </Label>
          <Value>{title}</Value>
        </Section>
        {upperLimitTxt && (
          <Section>
            <Label>{t('AIRSPACES-INFO_UPPER-LIMIT_TITLE')}: </Label>
            <Value>{upperLimitTxt}</Value>
          </Section>
        )}
        {lowerLimitTxt && (
          <Section>
            <Label>{t('AIRSPACES-INFO_LOWER-LIMIT_TITLE')}: </Label>
            <Value>{lowerLimitTxt}</Value>
          </Section>
        )}
        {oprHours && (
          <Section>
            <Label>{t('AIRSPACES-INFO_OPERATION-TIME_TITLE')}: </Label>
            <Value>{oprHours}</Value>
          </Section>
        )}
        {rmk && (
          <Section>
            <Label $display="block">{t('AIRSPACES-INFO_OBSERVATIONS_TITLE')}: </Label>
            <Value>{rmk}</Value>
          </Section>
        )}
        {commTxt && (
          <Section>
            <Label $display="block">{t('AIRSPACES-INFO_COMMUNICATIONS_TITLE')}: </Label>
            <Value>{commTxt}</Value>
          </Section>
        )}
        {classTxt && (
          <Section>
            <Label>{t('AIRSPACES-INFO_CLASS_TITLE')}: </Label>
            <Value>{classTxt}</Value>
          </Section>
        )}
      </Body>
    </>
  )
}

export { AirspacesFeatureInfo }
