import styled from 'styled-components'

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SelectorsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 24px;
  grid-column-gap: 12px;
`

export const Subtitle = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.neutralL5};
  width: 100%;
`

interface ToolContainerProps {
  gap: number
}

export const ToolContainer = styled.div<ToolContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: ${({ gap }) => gap}px;

  & > div {
    // MenuItem
    & > div:first-child {
      // TextContainer
      max-width: 100%;
      & > p:nth-child(1) {
        // Title
        ${({ theme }) => theme.fonts.infoSM}
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`
