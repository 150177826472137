import styled from 'styled-components'

interface ChartSelectorProps {
  $is_active?: boolean
}

export const Container = styled.div``

export const Input = styled.input<ChartSelectorProps>`
  display: none;

  &:focus ~ label {
    border: ${({ theme, $is_active }) => ($is_active ? theme.colors.secondaryL3 : 'transparent')};
  }
`

export const ChartSelectorButton = styled.label<ChartSelectorProps>`
  cursor: pointer;
  width: 80px;
  height: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background-color: ${({ theme, $is_active }) => ($is_active ? `${theme.colors.secondaryL1}bf` : 'transparent')};
  border: 1px solid ${({ theme, $is_active }) => ($is_active ? theme.colors.secondaryL3 : 'transparent')};

  &:hover {
    background-color: ${({ theme, $is_active }) =>
      $is_active ? `${theme.colors.secondaryL1}b3` : `${theme.colors.secondaryL1}26`};
  }

  &:active {
    background-color: ${({ theme, $is_active }) =>
      $is_active ? theme.colors.secondaryL1 : `${theme.colors.secondaryL1}52`};
  }
`

interface ChartImageProps {
  src: string
}

export const ChartImage = styled.div<ChartImageProps>`
  height: 64px;
  width: 64px;
  border-radius: 10px;

  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const ChartLabel = styled.p`
  ${({ theme }) => theme.fonts.textSM}
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutralL5};
  margin: 8px;
  flex: 1;
`
