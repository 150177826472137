import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { Result } from '@/domain/protocols/Result'

import type { IHttpClient } from '@/infra/http/IHttpClient'

import { IProcedureChartsRepository, RequestGetManifestResponse } from './IProcedureChartsRepository'
@injectable()
export class ProcedureChartsRepository implements IProcedureChartsRepository {
  constructor(@inject(InjectionTokens.HttpClient) private httpCLient: IHttpClient) {}

  getBaseUrl(): string {
    return 'https://nexatlas-static-files.nyc3.digitaloceanspaces.com/procedures'
  }

  async getProcedureChartsManifest(icao: string): Promise<Result<RequestGetManifestResponse>> {
    try {
      const response = await this.httpCLient.get<RequestGetManifestResponse>(
        `${this.getBaseUrl()}/${icao}/manifest.json`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)
      if (response.status !== 200) return Result.fail(response.response)

      return Result.ok(response.response)
    } catch (error) {
      return Result.fail(error)
    }
  }
}
