import { SerializableState } from '@domain/states/SerializableState'
import { ICachedHttpClient } from '@infra/cachedHttp/ICachedHttpClient'
import { LocalCachedHttpClient } from '@infra/cachedHttp/LocalCachedHttpClient'
import { AxiosHttpClient } from '@infra/http/AxiosHttpClient'
import { IHttpClient } from '@infra/http/IHttpClient'
import { IKeyValuePersister } from '@infra/keyValuePersister/IKeyValuePersister'
import { LocalStorageKeyValuePersister } from '@infra/keyValuePersister/LocalStorageKeyValuePersister'
import { ILogger } from '@infra/logger/ILogger'
import { LocalLogger } from '@infra/logger/LocalLogger'
import { IService } from '@services/IService'
import { StatePersister } from '@services/statePersister/StatePersister'
import { Container, interfaces } from 'inversify'

import { AuthState } from '@/domain/states/AuthState'
import { MapLayerState } from '@/domain/states/MapLayerState'
import { MapState } from '@/domain/states/MapState'
import { ProcedureChartState } from '@/domain/states/ProcedureChartState'
import { RouteState } from '@/domain/states/RouteState'
import { UndoRouteUseCase } from '@/domain/useCases/Route/undoRoute'

import { Authenticator } from '@/services/Authenticator/Authenticator'
import { MetricsService } from '@/services/Metrics/MetricsService'
import { ITrackingService } from '@/services/Tracking/ITrackingService'
import { TrackingService } from '@/services/Tracking/TrackingService'

import { AeroInfoRepository } from '@/data/AeroInfoRepository/AeroInfoRepository'
import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'
import { IAirspacesRepository } from '@/data/AirspacesRepository/IAirspacesRepository'
import { RemoteAirspaceRepository } from '@/data/AirspacesRepository/RemoteAirspacesRepository'
import { FeatureFlagsRepository } from '@/data/FeatureFlagsRepository/FeatureFlagsRepository'
import { IFeatureFlagsRepository } from '@/data/FeatureFlagsRepository/IFeatureFlagsRepository'
import { IMeteorologyRepository } from '@/data/MeteorologyRepository/IMeteorologyRepository'
import { RemoteMeteorologyRepository } from '@/data/MeteorologyRepository/RemoteMeteorologyRepository'
import { IProcedureChartsRepository } from '@/data/ProcedureChartsRepository/IProcedureChartsRepository'
import { ProcedureChartsRepository } from '@/data/ProcedureChartsRepository/ProcedureChartsRepository'
import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'
import { UserRoutesRepository } from '@/data/UserRoutesRepository/UserRoutesRepository'
import { IUserWaypointsRepository } from '@/data/UserWaypointsRepository/IUserWaypointsRepository'
import { UserWaypointsRepository } from '@/data/UserWaypointsRepository/UserWaypointsRepository'

import { I18n } from '@/i18n/I18n'
import { AccountWebClient } from '@/infra/Account/AccountWebClient'
import { IAccountWebClient } from '@/infra/Account/IAccountWebClient'
import { IFeatureFlagsProvider } from '@/infra/featureFlags/IFeatureFlagsProvider'
import { LocalFeatureFlagsProvider } from '@/infra/featureFlags/LocalFeatureFlagsProvider'
import { IGeocodeApi } from '@/infra/Geocode/IGeocodeApi'
import { MapboxApi } from '@/infra/Geocode/MapboxApi'
import { AmplitudeMetricsProvider } from '@/infra/MetricsProvider/AmplitudeMetricsProvider'
import { IGenericMetricsProvider } from '@/infra/MetricsProvider/IGenericMetricsProvider'

import { InjectionTokens } from './tokens'

export class InversionContainer {
  private static instance: InversionContainer
  private container: Container

  private constructor() {
    this.container = new Container({
      defaultScope: 'Singleton',
      skipBaseClassChecks: true
    })

    this.container.bind<I18n>(InjectionTokens.I18n).to(I18n)
    this.container.bind<IFeatureFlagsProvider>(InjectionTokens.FeatureFlagsProvider).to(LocalFeatureFlagsProvider)
    this.container.bind<ILogger>(InjectionTokens.Logger).to(LocalLogger)

    this.container.bind<IHttpClient>(InjectionTokens.HttpClient).to(AxiosHttpClient)
    this.container.bind<ICachedHttpClient>(InjectionTokens.CachedHttpClient).to(LocalCachedHttpClient)
    this.container.bind<IGeocodeApi>(InjectionTokens.GeocodeApi).to(MapboxApi)
    this.container.bind<IAccountWebClient>(InjectionTokens.AccountWebClient).to(AccountWebClient)
    this.container.bind<IGenericMetricsProvider>(InjectionTokens.AmplitudeMetricsProvider).to(AmplitudeMetricsProvider)

    this.container.bind<IKeyValuePersister>(InjectionTokens.KeyValuePersister).to(LocalStorageKeyValuePersister)

    // Global States
    this.container.bind<MapLayerState>(InjectionTokens.MapLayerState).to(MapLayerState)
    this.container.bind<ProcedureChartState>(InjectionTokens.ProcedureChartState).to(ProcedureChartState)
    this.container.bind<RouteState>(InjectionTokens.RouteState).to(RouteState)
    this.container.bind<MapState>(InjectionTokens.MapState).to(MapState)
    this.container.bind<AuthState>(InjectionTokens.AuthState).to(AuthState)

    this.container.bind<IService>(InjectionTokens.Authenticator).to(Authenticator)
    this.container
      .bind<SerializableState<any>[]>(InjectionTokens.StatePersisterStates)
      .toConstantValue([this.container.get<MapLayerState>(InjectionTokens.MapLayerState)])
    this.container.bind<IService>(InjectionTokens.StatePersister).to(StatePersister)
    this.container.bind<MetricsService>(InjectionTokens.MetricsService).to(MetricsService)
    this.container.bind<ITrackingService>(InjectionTokens.TrackingService).to(TrackingService)

    // Repositories
    this.container.bind<IFeatureFlagsRepository>(InjectionTokens.FeatureFlagsRepository).to(FeatureFlagsRepository)
    this.container.bind<IAirspacesRepository>(InjectionTokens.AirspaceRepository).to(RemoteAirspaceRepository)
    this.container.bind<IMeteorologyRepository>(InjectionTokens.MeteorologyRepository).to(RemoteMeteorologyRepository)
    this.container.bind<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository).to(AeroInfoRepository)
    this.container.bind<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository).to(UserRoutesRepository)
    this.container.bind<IUserWaypointsRepository>(InjectionTokens.UserWaypointsRepository).to(UserWaypointsRepository)
    this.container
      .bind<IProcedureChartsRepository>(InjectionTokens.ProcedureChartsRepository)
      .to(ProcedureChartsRepository),
      // useCases
      this.container.bind<UndoRouteUseCase>(InjectionTokens.UndoRouteUseCase).to(UndoRouteUseCase)
  }

  public static getInstance() {
    if (!InversionContainer.instance) {
      InversionContainer.instance = new InversionContainer()
    }

    return InversionContainer.instance
  }

  public getContainer() {
    return this.container
  }

  public resetContainer() {
    this.container.unbindAll()
  }
}
