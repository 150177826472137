import { ProcedureChartInfo } from '../protocols/ProcedureChart'
import { BaseState } from './BaseState'

export interface ProcedureChartStateProps {
  procedureChart: ProcedureChartInfo
}

const INITIAL_STATE: ProcedureChartStateProps = {
  procedureChart: null
}

export class ProcedureChartState extends BaseState<ProcedureChartStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setProcedureChart(procedureChart: ProcedureChartInfo) {
    this._updateStateProp('procedureChart', procedureChart)
  }
}
