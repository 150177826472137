import { ChevronUp } from '@nexds/web'
import styled from 'styled-components'

interface DrawerProps {
  isOpen: boolean
  handlePosition?: 'top' | 'middle' | 'bottom' | 'single'
  lastChild?: boolean
}

export const DrawerContent = styled.div<DrawerProps>`
  width: 100%;

  margin-top: ${({ isOpen }) => (isOpen ? '0' : '-150%')};

  padding-bottom: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  transition: margin-top 300ms;

  transition-delay: ${({ isOpen }) => (isOpen ? '0' : '100ms')};

  overflow: hidden;
`

export const Drawer = styled.div<DrawerProps>`
  width: 100%;

  height: min-content;

  margin-bottom: ${({ lastChild }) => (lastChild ? '-16px' : '0')};

  overflow: hidden;

  &::after {
    content: '';
    display: block;

    opacity: ${({ handlePosition, isOpen, lastChild }) =>
      handlePosition === 'single' || lastChild ? '0' : isOpen ? '1' : '0'};

    width: 80%;
    height: 1px;
    background: ${({ theme }) => theme.colors.secondaryL1};

    margin: 0 auto;

    transition: opacity 600ms;
  }
`
interface DrawerHandleProps {
  positionInGroup: 'top' | 'middle' | 'bottom' | 'single'
}

export const DrawerHandle = styled.button<DrawerHandleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  outline: none;

  border-radius: ${({ positionInGroup }) => {
    if (positionInGroup === 'single') return '10px'
    if (positionInGroup === 'top') return '10px 10px 0 0'
    if (positionInGroup === 'middle') return '0'
    if (positionInGroup === 'bottom') return '0 0 10px 10px'
  }};

  padding: 10px 14px 10px 14px;

  border: none;
  background: ${({ theme }) => theme.colors.secondary};

  cursor: pointer;

  width: 100%;
  height: 48px;

  ${({ theme }) => theme.fonts.infoMD}
`

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  gap: 12px;
`

export const Label = styled.label`
  ${({ theme }) => theme.fonts.labelMD}
`

export const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 48px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  padding: 10px 14px 10px 14px;

  & > input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.secondaryL2};
    outline: none;
  }

  & > input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;

    border-radius: 100%;
    width: 20px;
    height: 20px;

    background: ${({ theme }) => theme.colors.neutralL5};
    cursor: pointer;
  }

  & > input[type='range']::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.colors.neutralL5};
    cursor: pointer;
  }
`

export const RangeText = styled.span`
  ${({ theme }) => theme.fonts.infoMD}
`

interface LegendContainerProps {
  place: number
  legendsLength: number
}

export const LegendContainer = styled.div<LegendContainerProps>`
  height: 48px;
  width: 100%;
  padding: 0 8px;

  background: ${({ theme }) => theme.colors.secondary};

  border-bottom: 1px solid #2e3a40;

  ${({ place, legendsLength }) => {
    if (place === 0) {
      return `
      border-radius: 10px 10px 0 0;
      `
    }
    if (place === legendsLength - 1) {
      return `
      border-radius: 0 0 10px 10px;
      border: none;
      `
    }
    return 'border-radius: 0;'
  }}

  display: flex;
  align-items: center;
  gap: 8px;

  & > img {
    width: 32px;
    height: 32px;
  }

  & > p {
    ${({ theme }) => theme.fonts.infoMD}
  }
`

export const LegendsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
