import { useEffect, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { ProcedureChartInfo } from '@/domain/protocols/ProcedureChart'
import { ProcedureChartState } from '@/domain/states/ProcedureChartState'

import { useInjection } from '@/presentation/contexts/InjectionContext'

import { MapProcedureChart } from '../components/MapProcedureChart/MapProcedureChart'

function MapProcedureChartPresenter() {
  const procedureChartState = useInjection<ProcedureChartState>(InjectionTokens.ProcedureChartState)
  const [procedureChart, setProcedureChart] = useState<ProcedureChartInfo>(null)

  useEffect(() => {
    const procedureChartStateSubscription = procedureChartState.getState().subscribe((state) => {
      setProcedureChart(state.procedureChart)
    })

    return () => {
      procedureChartStateSubscription.unsubscribe()
    }
  }, [procedureChartState])

  return procedureChart && <MapProcedureChart url={procedureChart.uri} boundaries={procedureChart.boundaries} />
}

export { MapProcedureChartPresenter }
