import styled, { keyframes } from 'styled-components'

export const SplashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: 100vw;
  margin: 0 auto;
`

const flickerAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`

export const Logo = styled.img`
  height: 10%;
  margin-bottom: 30px;

  -webkit-animation: ${flickerAnimation} 3s infinite;
  -moz-animation: ${flickerAnimation} 3s infinite;
  -o-animation: ${flickerAnimation} 3s infinite;
  animation: ${flickerAnimation} 3s infinite;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
`
