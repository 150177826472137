/* global __APP_VERSION__ */

import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import CheckBold from '@assets/check-bold.svg?react'
import { Rating } from '@mui/material'
import { Assets, Button, Select, SelectItem, Star, StarFilled, TextField } from '@nexds/web'
import styled, { useTheme } from 'styled-components'

import { InjectionTokens } from '@/controller/tokens'

import { AuthState, AuthStateProps } from '@/domain/states/AuthState'

import { IHttpClient } from '@/infra/http/IHttpClient'
import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { getStateColor } from '@/presentation/utils'
import { appZIndex } from '@/presentation/utils/zIndexMapper'

export interface ReviewProps {
  rating?: number
  type: 'suggestion' | 'bug'
  feature:
    | 'general'
    | 'map'
    | 'routeOnMap'
    | 'routePanel'
    | 'routesSaved'
    | 'procedureCharts'
    | 'market'
    | 'mapCharts'
    | 'baseMap'
    | 'meteorology'
    | 'airspaces'
    | 'pointsOnMap'
    | 'ruler'
  comment: string
  version: string
  product: 'planning-app'
}

export type ReviewSentProps = { wasSent: boolean; isSuccess: boolean }

function FeedbackCollectorPresenter() {
  const theme = useTheme()
  const VERSION = __APP_VERSION__
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const httpClient = useInjection<IHttpClient>(InjectionTokens.HttpClient)
  const [authState] = useGlobalState<AuthState, AuthStateProps>(InjectionTokens.AuthState)

  const [ratingValue, setRatingValue] = useState(0)
  const [feedbackTypeSelected, setFeedbackTypeSelected] = useState<ReviewProps['type']>(null)

  const authToken = useMemo(() => authState.token, [authState])
  const [isOpen, setIsOpen] = useState(false)
  const [reviewSent, setReviewSent] = useState<ReviewSentProps>({
    wasSent: false,
    isSuccess: false
  })

  const headerMessage = useMemo(() => {
    if (reviewSent.wasSent) {
      if (reviewSent.isSuccess)
        return (
          <>
            <CheckBold />
            Feedback enviado
          </>
        )
      else return '⚠️ Ops! Algo deu errado.'
    } else if (feedbackTypeSelected === 'bug') return 'Relate um bug ou problema técnico'
    else if (feedbackTypeSelected === 'suggestion') return 'Adicione um comentário ou feedback'
    else return 'Escolha a categoria do seu feedback'
  }, [feedbackTypeSelected, reviewSent])

  const sendReview = async (reviewBody: ReviewProps) => {
    const response = await httpClient.post(`${import.meta.env.VITE_NEXATLAS_API_URL}/reviews`, reviewBody, {
      headers: {
        Authorization: `Token ${authToken}`
      }
    })

    if (response.success === false) return false
    if (response.status < 200 || response.status > 300) return false

    return true
  }

  const onSubmit = async (data: FieldValues) => {
    console.log(data['comment-suggestion'], data['comment-bug'], ratingValue, feedbackTypeSelected)

    const body: ReviewProps = {
      rating: feedbackTypeSelected === 'suggestion' ? ratingValue : null,
      type: feedbackTypeSelected,
      feature: 'general',
      comment: feedbackTypeSelected === 'suggestion' ? data['comment-suggestion'] : data['comment-bug'], // TODO: separar por tipo
      version: VERSION,
      product: 'planning-app'
    }
    const isSuccess = await sendReview(body)

    if (isSuccess) {
      reset()
      setRatingValue(0)
    }
    setReviewSent({ wasSent: true, isSuccess })
  }

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setFeedbackTypeSelected(null)
    setReviewSent({ wasSent: false, isSuccess: false })
  }, [])

  useEffect(() => {
    setReviewSent({ isSuccess: false, wasSent: false })
  }, [feedbackTypeSelected])

  return (
    <Container isOpen={isOpen}>
      <ReviewButton onClick={() => setIsOpen((prev) => !prev)}>{isOpen ? 'Fechar' : '📢 Enviar feedback'}</ReviewButton>
      {isOpen && (
        <ReviewPopupContainer>
          <Header>
            <Title>{headerMessage}</Title>
            <ActionButton onClick={handleClose}>
              <Assets.Close size={32} color={theme.colors.secondaryL3} />
            </ActionButton>
          </Header>
          <Body flexDirection={!feedbackTypeSelected ? 'row' : 'column'} gap={!feedbackTypeSelected ? 32 : 16}>
            {reviewSent.wasSent ? (
              <>
                <Message>
                  {reviewSent.isSuccess ? (
                    <>
                      Obrigado!
                      <br />
                      <br />
                      Sua opinião faz toda a diferença para que possamos continuar melhorando.
                    </>
                  ) : (
                    <>Parece que um erro inesperado aconteceu. Tente novamente mais tarde.</>
                  )}
                </Message>
                <Button
                  label={reviewSent.isSuccess ? 'Fechar' : 'Voltar'}
                  color="secondary"
                  variant="filled"
                  size="sm"
                  onPress={
                    reviewSent.isSuccess ? handleClose : () => setReviewSent((prev) => ({ ...prev, wasSent: false }))
                  }
                />
                {reviewSent.isSuccess && (
                  <Button
                    label="Enviar outro feedback"
                    color="secondary"
                    variant="outline"
                    size="sm"
                    onPress={() => {
                      setFeedbackTypeSelected(null)
                      setReviewSent({ wasSent: false, isSuccess: false })
                    }}
                  />
                )}
              </>
            ) : feedbackTypeSelected === 'bug' ? (
              <>
                <InputContainer>
                  <TextField
                    multiline
                    maxLength={1000}
                    helpMessage={errors?.comment ? 'Descreva o problema' : ''}
                    error={!!errors?.comment}
                    placeholder="Tente ser breve e direto, relatando as etapas que você realizou e que resultaram no problema."
                    {...register('comment-bug', { required: true })}
                  />
                </InputContainer>
                <Button
                  label="Enviar"
                  color="secondary"
                  variant="filled"
                  size={'sm'}
                  onPress={handleSubmit(onSubmit)}
                />
              </>
            ) : feedbackTypeSelected === 'suggestion' ? (
              <>
                <RatingContainer>
                  <RatingLabel>Avaliação:</RatingLabel>
                  <Rating
                    icon={<StarFilled color={theme.colors.yellowL0} size={20} />}
                    emptyIcon={<StarFilled color={theme.colors.secondaryL1} size={20} />}
                    name="rating-stars"
                    value={ratingValue}
                    onChange={(_, newValue) => {
                      setRatingValue(newValue)
                    }}
                  />
                </RatingContainer>
                <InputContainer>
                  <TextField
                    multiline
                    helpGutter={true}
                    labelGutter={false}
                    maxLength={1000}
                    helpMessage={errors?.comment ? 'Escreva um feedback' : ''}
                    error={!!errors?.comment}
                    placeholder="Como está sendo a sua experiência com a atualização do aplicativo web?"
                    {...register('comment-suggestion', { required: true })}
                  />
                </InputContainer>
                <Button
                  label="Enviar"
                  color="secondary"
                  variant="filled"
                  size={'sm'}
                  onPress={handleSubmit(onSubmit)}
                />
              </>
            ) : (
              <>
                <TypeSelector>
                  <TypeButton onClick={() => setFeedbackTypeSelected('bug')}>
                    <Assets.Warning size={36} color={theme.colors.neutralL5} />
                  </TypeButton>
                  <TypeLabel>Problema</TypeLabel>
                </TypeSelector>
                <TypeSelector>
                  <TypeButton onClick={() => setFeedbackTypeSelected('suggestion')}>
                    <Assets.News size={36} color={theme.colors.neutralL5} />
                  </TypeButton>
                  <TypeLabel>Comentário</TypeLabel>
                </TypeSelector>
              </>
            )}
          </Body>
        </ReviewPopupContainer>
      )}
    </Container>
  )
}

export { FeedbackCollectorPresenter }

interface ContainerProps {
  isOpen?: boolean
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  z-index: ${({ isOpen }) => (isOpen ? appZIndex.CHAT_OPEN : appZIndex.CHAT_CLOSED)};
  bottom: 23px;
  right: 10px;
`

const ReviewButton = styled.button`
  border: none;
  cursor: pointer;
  height: 43px;
  padding: 14px 19px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};
  ${({ theme }) => theme.fonts.titleXS}
`

const ReviewPopupContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 16px);
  right: -1px;

  width: 500px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryL2};

  background-color: ${({ theme }) => theme.colors.secondaryD1};
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryL2};

  height: 80px;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.h2`
  ${({ theme }) => theme.fonts.titleMD}
  display: flex;
  align-items: center;
  gap: 4px;
`

const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface BodyProps {
  flexDirection: 'row' | 'column'
  gap: number
}

const Body = styled.div<BodyProps>`
  padding: 16px;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: center;
  align-items: stretch;
  gap: ${({ gap }) => gap}px;
  height: 100%;
`

const RatingContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const RatingLabel = styled.h5`
  ${({ theme }) => theme.fonts.h5}
`

const InputContainer = styled.div`
  border-radius: 10px;

  & textarea {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 112px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      border-radius: 20px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.secondaryL3};
      border-radius: 20px;
    }
  }
`

const Message = styled.p`
  ${({ theme }) => theme.fonts.h5}
`

const TypeSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 23px;
  cursor: pointer;
`

const TypeButton = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 112px;
  height: 112px;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondary, 'hover')};
  }
`

const TypeLabel = styled.p`
  ${({ theme }) => theme.fonts.titleXS}
  text-align: center;
`
