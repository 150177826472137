import CompleteLogoNexatlas from '@assets/complete-logo-nexatlas-dark-text.svg'

import { SplashContainer, Logo } from './SplashScreen.styles'

function SplashScreen() {
  return (
    <SplashContainer>
      <Logo src={CompleteLogoNexatlas} />
    </SplashContainer>
  )
}

export { SplashScreen }
