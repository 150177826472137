import { useMemo } from 'react'

import * as Turf from '@turf/turf'
import GeoJSON from 'geojson'

import { AnyAirspace } from '@/domain/models/Airspace'

import { AirspaceType } from '@/data/AirspacesRepository/IAirspacesRepository'

import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { stackIds } from '../../containers/MapStackPresenter'
import { MapGeoJsonLayer } from '../MapView/MapGeoJsonLayer'
import { MapGeoJsonSource } from '../MapView/MapGeoJsonSource'
import { MapSymbolLayer } from '../MapView/MapSymbolLayer'
import { MapSymbolSource } from '../MapView/MapSymbolSource'
import {
  airspaceColor,
  airspaceImages,
  airspaceLabelMinLevel,
  airspaceLabelSeparationDecimals,
  airspaceTextColor
} from './MapAirspace.styles'

export interface MapAirspaceProps {
  airspaceId: AirspaceType
  borderSourceId: string
  borderLayerId: string
  symbolSourceId: string
  symbolLayerId: string
  features: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
  activeAirspace?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
  onPress?: (code: string) => void
}

function MapAirspace(props: MapAirspaceProps) {
  const { airspaceId, borderLayerId, borderSourceId, symbolLayerId, symbolSourceId, features, onPress } = props

  const symbolFeatureCollection: GeoJSON.FeatureCollection<GeoJSON.Point, AnyAirspace> = useMemo(() => {
    const symbolFeatures: Array<GeoJSON.Feature<GeoJSON.Point, AnyAirspace>> = []

    for (const feature of features.features) {
      const center = Turf.centerOfMass(feature, {
        properties: {
          ...feature.properties,
          codeLabel: feature.properties?.code?.replace('_', ' ')
        }
      })

      symbolFeatures.push(center as GeoJSON.Feature<GeoJSON.Point, AnyAirspace>)
    }

    const visited: { [key: string]: number } = {}

    symbolFeatures.forEach((feature) => {
      const separationDecimals = airspaceLabelSeparationDecimals[airspaceId]
      const key = `${feature.geometry.coordinates[0].toFixed(
        separationDecimals
      )}-${feature.geometry.coordinates[1].toFixed(separationDecimals)}`
      const timesVisited = visited[key]
      if (timesVisited > 0) {
        visited[key]++

        feature.geometry.coordinates = Turf.destination(
          feature.geometry.coordinates,
          0.025 * (timesVisited % 2 === 0 ? Math.floor(timesVisited / 2) : Math.floor(-timesVisited / 2)),
          0,
          {
            units: 'degrees'
          }
        ).geometry.coordinates
      } else {
        visited[key] = 1
      }
    })

    return {
      type: 'FeatureCollection',
      features: symbolFeatures
    }
  }, [features])

  return (
    <>
      <MapGeoJsonSource id={borderSourceId} data={features}>
        <MapGeoJsonLayer
          types={['line']}
          id={borderLayerId}
          sourceId={borderSourceId}
          beforeLayerId={stackIds.airspaces[airspaceId]}
          borderColor={airspaceColor[airspaceId]}
          borderWidth={['interpolate', ['linear'], ['zoom'], 0, 0.5, 10, 5, 12, 20, 22, 25]}
          borderOpacity={1}
          onBorderClick={(features: GeoJSON.Feature[]) => {
            onPress?.(features[0].properties?.code)
          }}
        />
      </MapGeoJsonSource>

      <MapSymbolSource id={symbolSourceId} data={symbolFeatureCollection}>
        <MapSymbolLayer
          id={symbolLayerId}
          sourceId={symbolSourceId}
          beforeLayerId={stackIds.airspacesLabel[airspaceId]}
          imageSrc={airspaceImages[airspaceId]}
          layout={{
            'text-field': ['get', 'codeLabel'],
            'text-size': 12,
            'text-font': ['Open Sans Bold'],
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [3, 6, 3, 6],
            'icon-allow-overlap': true,
            'text-ignore-placement': true,
            'text-allow-overlap': true,
            'text-anchor': 'center'
          }}
          iconColor={airspaceColor[airspaceId]}
          textColor={airspaceTextColor[airspaceId]}
          minZoomLevel={airspaceLabelMinLevel[airspaceId]}
          onSymbolClick={(e) => {
            sendMetrics('PLANNINGAPP_MAP_AIRSPACES_SELECTED')
            onPress?.(e.features[0].properties?.code)
          }}
        />
      </MapSymbolSource>
    </>
  )
}

export { MapAirspace }
