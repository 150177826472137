import { Assets, MenuGroup, MenuItem } from '@nexds/web'
import { useTheme } from 'styled-components'

import { Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { MapImperativeInterface } from '../../../MapScreen/interfaces/MapImperativeInterface'
import {
  ActionButton,
  ActionButtonsWrapper,
  ListContainer,
  ResultWrapper,
  SearchListContainer
} from './SearchList.styles'

interface SearchListProps {
  waypoints: Waypoint[]
  maxHeight: number
  onWaypointClick: (waypoint: Waypoint) => void
}

function SearchList(props: SearchListProps) {
  const { waypoints, maxHeight, onWaypointClick } = props
  const theme = useTheme()

  const handleShowInfo = (infoCode: string) => {
    window.open(`https://aisweb.decea.gov.br/?i=aerodromos&codigo=${infoCode}`)
  }

  const handleLocateWaypoint = (coordinates: Coordinates) => {
    MapImperativeInterface.flyToCoordinates(coordinates, 8, 2000)
  }

  return (
    <SearchListContainer>
      <MenuGroup isLightStyle>
        <ListContainer maxHeight={maxHeight}>
          {waypoints &&
            waypoints.map((waypoint) => (
              <ResultWrapper key={waypoint.id.toString()}>
                <MenuItem
                  size="sm"
                  isLightStyle
                  title={waypoint.getDisplayName()}
                  description={waypoint.extra?.isAddress ? undefined : waypoint.getTypeName()}
                  onClick={() => onWaypointClick(waypoint)}
                />
                <ActionButtonsWrapper>
                  {waypoint.waypointType?.codeName === 'ADHP' && (
                    <ActionButton onClick={() => (waypoint.code ? handleShowInfo(waypoint.code) : undefined)}>
                      <Assets.Info color={theme.colors.secondaryL2} size={'sm'} />
                    </ActionButton>
                  )}
                  <ActionButton onClick={() => handleLocateWaypoint(waypoint.coordinates)}>
                    <Assets.CenterPoint color={theme.colors.secondaryL2} size={'sm'} />
                  </ActionButton>
                </ActionButtonsWrapper>
              </ResultWrapper>
            ))}
        </ListContainer>
      </MenuGroup>
    </SearchListContainer>
  )
}

export { SearchList }
