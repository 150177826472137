import { useEffect, useState } from 'react'

import { MenuGroup, MenuItem } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import icons from '@/presentation/modules/MapScreen/components/MapADHP/icons'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ToolContainer } from '../ToolbarTools.styles'
import { LegendsContainer } from './ADHPTool.styles'
import { Drawer } from './Drawer'
import { Legend } from './Legend'
import { Slider } from './Slider'

function ADHPTool() {
  const { t } = useI18n()

  const [mapLayerState, mapLayerStateMutator] = useGlobalState<MapLayerState, MapLayerStateProps>(
    InjectionTokens.MapLayerState
  )

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [legendsOpen, setLegendsOpen] = useState(false)

  useEffect(() => {
    if (filtersOpen) {
      setLegendsOpen(false)
    }
  }, [filtersOpen])

  useEffect(() => {
    if (legendsOpen) {
      setFiltersOpen(false)
      sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-LEGEND_CLICKED')
    }
  }, [legendsOpen])

  const legends = [
    { icon: icons['legend-aerodrome-paved'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_PAVED-AERODROME') },
    {
      icon: icons['legend-military-aerodrome-paved'],
      meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_PAVED-MILITARY-AERODROME')
    },
    { icon: icons['legend-aerodrome-unpaved'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_UNPAVED-AERODROME') },
    {
      icon: icons['legend-military-aerodrome-unpaved'],
      meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_UNPAVED-MILITARY-AERODROME')
    },
    { icon: icons['legend-helipad'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_HELIPAD') },
    { icon: icons['legend-military-helipad'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_MILITARY-HELIPAD') },
    { icon: icons['legend-user-waypoint'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_USER-WAYPOINT') }
  ]

  return (
    <ToolContainer gap={16}>
      <MenuGroup>
        <MenuItem
          title={t('TOOLBAR_MAP-POINTS_ITEM_AERODROMES_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.aerodromes}
          onClick={() => {
            mapLayerStateMutator.setAerodromes(!mapLayerState.aerodromes)

            sendMetrics('PLANNINGAPP_TOOLBAR_ADHP_SELECTED', {
              extra: 'aerodrome',
              active: !mapLayerState.aerodromes
            })
          }}
        />
        <Drawer isOpen={filtersOpen} setIsOpen={setFiltersOpen} handlePosition="bottom" title="Filtros">
          <Slider
            label={t('TOOLBAR_MAP-POINTS_ITEM_RUNWAY-LENGTH_LABEL')}
            id="min-runway-length"
            max={2500}
            min={0}
            step={100}
            value={mapLayerState.minRunwayLength}
            formatedValue={`${mapLayerState.minRunwayLength.toString().padStart(4, '0')}m`}
            onChange={(e) => {
              mapLayerStateMutator.setMinRunwayLength(parseInt(e.target.value))
            }}
            onDragExit={() => {
              sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-MIN-TRACK-LENGTH_CLICKED')
            }}
          />

          <MenuGroup>
            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_PAVED-RUNWAY_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapLayerState.pavedRunway}
              onClick={() => {
                mapLayerStateMutator.setPavedRunway(!mapLayerState.pavedRunway)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'paved runway',
                  active: !mapLayerState.pavedRunway
                })
              }}
            />

            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_NIGHT-OPERATION_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapLayerState.nightOperation}
              onClick={() => {
                mapLayerStateMutator.setNightOperation(!mapLayerState.nightOperation)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'night operation',
                  active: !mapLayerState.nightOperation
                })
              }}
            />

            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_ATS_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapLayerState.ats}
              onClick={() => {
                mapLayerStateMutator.setAts(!mapLayerState.ats)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'ats',
                  active: !mapLayerState.ats
                })
              }}
            />

            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_IFR_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapLayerState.ifr}
              onClick={() => {
                mapLayerStateMutator.setIfr(!mapLayerState.ifr)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'ifr',
                  active: !mapLayerState.ifr
                })
              }}
            />
          </MenuGroup>
        </Drawer>
      </MenuGroup>

      <MenuItem
        title={t('TOOLBAR_MAP-POINTS_ITEM_HELIPADS_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.helipads}
        onClick={() => {
          mapLayerStateMutator.setHelipads(!mapLayerState.helipads)

          sendMetrics('PLANNINGAPP_TOOLBAR_ADHP_SELECTED', {
            extra: 'helipad',
            active: !mapLayerState.helipads
          })
        }}
      />

      <MenuItem
        title={t('TOOLBAR_MAP-POINTS_ITEM_USER-WAYPOINTS_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.userWaypoints}
        onClick={() => {
          mapLayerStateMutator.setUserWaypoints(!mapLayerState.userWaypoints)

          sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-USER-WAYPOINTS_SELECTED', {
            active: !mapLayerState.userWaypoints
          })
        }}
      />

      <Drawer
        isOpen={legendsOpen}
        setIsOpen={setLegendsOpen}
        title={t('TOOLBAR_MAP-POINTS_ITEM_LEGENDS_LABEL')}
        lastChild
      >
        <LegendsContainer>
          {legends.map((legend, place) => (
            <Legend
              key={legend.meaning}
              icon={legend.icon}
              meaning={legend.meaning}
              legendsLength={legends.length}
              place={place}
            />
          ))}
        </LegendsContainer>
      </Drawer>
    </ToolContainer>
  )
}

export { ADHPTool }
