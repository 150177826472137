import * as Turf from '@turf/turf'
import Geomagnetism from 'geomagnetism'

import { Coordinates } from '@/domain/protocols/Coordinates'

/**
 * Corrects the magnetic declination of a given heading
 *
 * @param heading Heading in circular degrees (North 0, positive clockwise)
 * @param startCoords Coordinates of the start point
 * @returns The heading corrected with the Magnetic Declination
 */
export const fixHeadingByMagneticDeclination = (heading: number, startCoords: Coordinates, invert?: boolean) => {
  let newHeading
  const declination = Geomagnetism.model().point([startCoords.latitude, startCoords.longitude]) as {
    decl: number
  }

  if (invert) {
    newHeading = heading + declination.decl
  } else {
    newHeading = heading - declination.decl
  }

  if (newHeading <= 0) {
    newHeading = 360 + newHeading
  }

  if (newHeading > 360) {
    newHeading = newHeading - 360
  }

  return newHeading
}

/**
 * Calculates the geographic heading between two coordinates
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns Magnetic Heading between the two coordinates, corrected by the
 * magnetic declination
 */
export const calculateHeading = (start: Coordinates, end: Coordinates) => {
  const startPoint = Turf.point(start.toArray())
  const endPoint = Turf.point(end.toArray())

  const geographicBearing = Turf.bearing(startPoint, endPoint)
  const azimuthBearing = Turf.bearingToAzimuth(geographicBearing)

  return azimuthBearing
}

/**
 * Calculates the magnetic heading between two coordinates
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns Magnetic Heading between the two coordinates, corrected by the
 * magnetic declination
 */
export const calculateMagneticHeading = (start: Coordinates, end: Coordinates, invert?: boolean) => {
  return fixHeadingByMagneticDeclination(calculateHeading(start, end), start, invert)
}

/**
 * Converts a decimal coordinate (number) in a DMS format(string)
 *
 * @param coordinate Decimal coordinates
 * @param threeDegreesDigits Displays the result with a 3-digit degrees (with zero padding)
 * @returns Coordinate in DMS format
 */
export const toDegreesMinutesAndSeconds = (coordinate: number, threeDegreesDigits: boolean = false): string => {
  const absolute = Math.abs(coordinate)
  let degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  let minutes = Math.floor(minutesNotTruncated)
  let seconds = Math.round((minutesNotTruncated - minutes) * 60)

  if (seconds === 60) {
    minutes += 1
    seconds = 0
  }

  if (minutes === 60) {
    degrees += 1
    minutes = 0
  }

  if (degrees > 180 && threeDegreesDigits) {
    degrees = degrees - 360
  }

  if (degrees > 90 && !threeDegreesDigits) {
    degrees = degrees - 180
  }

  const padDegrees = `0${degrees}`.slice(threeDegreesDigits ? -3 : -2)
  const padMinutes = `0${minutes}`.slice(-2)
  const padSeconds = `0${seconds}`.slice(-2)

  return `0${padDegrees}${padMinutes}${padSeconds}`.slice(threeDegreesDigits ? -7 : -6)
}

/**
 * Converts both latitude and longitude in a unique string
 * in a DMS format
 *
 * @param latitude Decimal latitude
 * @param longitude Decimal longitude
 * @returns A string formatted in DMS of both lat and lng
 */
export const convertDecimalDegreesToDMS = (latitude: number, longitude: number): string => {
  const lat = toDegreesMinutesAndSeconds(latitude)
  const latitudeCardinal = latitude >= 0 ? 'N' : 'S'

  const long = toDegreesMinutesAndSeconds(longitude, true)
  const longitudeCardinal = longitude >= 0 ? 'E' : 'W'

  return `${lat}${latitudeCardinal} ${long}${longitudeCardinal}`
}
/**
 * Converts both latitude and longitude in a unique string
 * in a DMS format, ignoring seconds
 * @param latitude Decimal latitude
 * @param longitude Decimal longitude
 * @returns A string formatted in DMS of both lat and lng without seconds
 */
export const convertDecimalDegreesToDMSWithoutSeconds = (latitude: number, longitude: number): string => {
  const lat = toDegreesMinutesAndSeconds(latitude)
  const latitudeCardinal = latitude >= 0 ? 'N' : 'S'

  const long = toDegreesMinutesAndSeconds(longitude, true)
  const longitudeCardinal = longitude >= 0 ? 'E' : 'W'

  return `${lat.slice(0, -2)}${latitudeCardinal}${long.slice(0, -2)}${longitudeCardinal}`
}

/**
 * Convert DMS To Decimal Degrees
 *
 * @param dms A string formatted in DMS
 * @returns An lat and lng in degrees
 */
export const DMSToDecimal = (dms: RegExpExecArray): Coordinates => {
  const lat = parseInt(dms[1]) + (parseInt(dms[2]) || 0) / 60 + (parseInt(dms[3]) || 0) / 3600
  if (dms.length === 5) {
    return Coordinates.create({
      latitude: ['n', 'N'].includes(dms[4]) ? lat : -lat,
      longitude: 0
    })
  }
  const lng = parseInt(dms[5]) + (parseInt(dms[6]) || 0) / 60 + (parseInt(dms[7]) || 0) / 3600
  return Coordinates.create({
    latitude: ['n', 'N'].includes(dms[4]) ? lat : -lat,
    longitude: ['E', 'e', 'L', 'l'].includes(dms[8]) ? lng : -lng
  })
}

/**
 * Calculates the distance in degrees between two points with high precision
 *
 * @variation degreesPythagoreanDistance
 * This function have a slow performance for more than 40.000 registers (250ms in a medium 2021 hardware).
 * For bigger arrays, use degreesPythagoreanDistance (55ms for 40.000 registers).
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns The distance in degrees between two points
 */
export const degreesDistance = (start: Coordinates, end: Coordinates) => {
  const startPoint = Turf.point(start.toArray())
  const endPoint = Turf.point(end.toArray())
  return Turf.distance(startPoint, endPoint, { units: 'degrees' })
}

/**
 * Calculates the distance in nautical miles between two points with high precision
 *
 * @variation nmPythagoreanDistance
 * This function have a slow performance for more than 40.000 registers (250ms in a medium 2021 hardware).
 * For bigger arrays, use nmPythagoreanDistance (55ms for 40.000 registers).
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns The distance in nm between two points
 */
export const nmDistance = (start: Coordinates, end: Coordinates) => {
  const startPoint = Turf.point(start.toArray())
  const endPoint = Turf.point(end.toArray())
  return Turf.distance(startPoint, endPoint, { units: 'nauticalmiles' })
}

/**
 * Calculates the distance in kilometers between two points with high precision
 *
 * @variation kmPythagoreanDistance
 * This function have a slow performance for more than 40.000 registers (250ms in a medium 2021 hardware).
 * For bigger arrays, use kmPythagoreanDistance (55ms for 40.000 registers).
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns The distance in km between two points
 */
export const kmDistance = (start: Coordinates, end: Coordinates) => {
  const startPoint = Turf.point(start.toArray())
  const endPoint = Turf.point(end.toArray())
  return Turf.distance(startPoint, endPoint, { units: 'kilometers' })
}

/**
 * Calculates the distance in degrees between two points with low precision
 *
 * @warning This function has a poor precision for coordinates
 * far from the equator. DO NOT USE for important measures.
 * @variation degreesDistance for important measures.
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns The distance in degrees between two points
 */
export const degreesPythagoreanDistance = (coords1: Coordinates, coords2: Coordinates) => {
  return Math.sqrt(
    Math.pow(coords2.longitude - coords1.longitude, 2) + Math.pow(coords2.latitude - coords1.latitude, 2)
  )
}

/**
 * Calculates the distance in nautical miles between two points with low precision
 *
 * @warning This function has a poor precision for coordinates
 * far from the equator. DO NOT USE for important measures.
 * @variation nmDistance for important measures.
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns The distance in nm between two points
 */
export const nmPythagoreanDistance = (coords1: Coordinates, coords2: Coordinates) => {
  const hypotenuse = Math.sqrt(
    Math.pow(coords2.longitude - coords1.longitude, 2) + Math.pow(coords2.latitude - coords1.latitude, 2)
  )

  return hypotenuse * 60
}

/**
 * Calculates the distance in kilometers between two points with low precision
 *
 * @warning This function has a poor precision for coordinates
 * far from the equator. DO NOT USE for important measures.
 * @variation kmDistance for important measures.
 *
 * @param start Coordinates of start point
 * @param end Coordinates of end point
 * @returns The distance in km between two points
 */
export const kmPythagoreanDistance = (start: Coordinates, end: Coordinates) => {
  const hypotenuse = Math.sqrt(
    Math.pow(end.longitude - start.longitude, 2) + Math.pow(end.latitude - start.latitude, 2)
  )

  return hypotenuse * 111
}

/**
 * Calculates the distance in nautical miles between two points and a line segment
 * (a line between two points)
 *
 * @param coord Coordinates of the point point
 * @param l1 Coordinates of the start point of the line segment
 * @param l2 Coordinates of the end point of the line segment
 * @returns The distance in nautical miles between two points and a line segment
 */
export const distanceCoordToSegment = (coord: Coordinates, l1: Coordinates, l2: Coordinates) => {
  const point = Turf.point(coord.toArray())
  const line = Turf.lineString([l1.toArray(), l2.toArray()])

  return Turf.pointToLineDistance(point, line, { units: 'nauticalmiles' })
}

/**
 * Calculate Zoom Level of the map by a given boundaries
 *
 * @param corners The corners
 * @param mapWidth Width of the map view
 * @param mapHeight Height of the map view
 * @returns The map zoom that fit the boundaries
 */
export const getZoomByItemCorners = (
  corners: [Coordinates, Coordinates, Coordinates, Coordinates],
  mapWidth: number,
  mapHeight: number
): number => {
  const WORLD_DIM = { height: 256, width: 256 }
  const ZOOM_MAX = 20

  const getBoundsByCorners = (
    corners: [Coordinates, Coordinates, Coordinates, Coordinates]
  ): {
    ne: Coordinates
    sw: Coordinates
  } => {
    const minLat = Math.min(...corners.map((corner) => corner.latitude))
    const maxLat = Math.max(...corners.map((corner) => corner.latitude))
    const minLng = Math.min(...corners.map((corner) => corner.longitude))
    const maxLng = Math.max(...corners.map((corner) => corner.longitude))

    return { ne: Coordinates.createFromArray([maxLng, maxLat]), sw: Coordinates.createFromArray([minLng, minLat]) }
  }

  const latRad = (lat: number) => {
    const sin = Math.sin((lat * Math.PI) / 180)
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }

  const zoom = (mapPx: number, worldPx: number, fraction: number) => {
    return Math.log(mapPx / worldPx / fraction) / Math.LN2
  }

  const { ne, sw } = getBoundsByCorners(corners)

  const latFraction = (latRad(ne.latitude) - latRad(sw.latitude)) / Math.PI

  const lngDiff = ne.longitude - sw.longitude
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  const latZoom = zoom(mapHeight, WORLD_DIM.height, latFraction)
  const lngZoom = zoom(mapWidth, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export const magneticBearing = (start: Coordinates, end: Coordinates) => {
  const startPoint = Turf.point([start.longitude, start.latitude])
  const endPoint = Turf.point([end.longitude, end.latitude])

  const bearing = Turf.bearing(startPoint, endPoint)
  const declination = Geomagnetism.model().point([start.latitude, start.longitude]) as {
    decl: number
  }

  let bearingMag = Math.round(bearing - declination.decl)

  if (bearingMag === 0) {
    bearingMag = 360
  } else if (bearingMag < 0) {
    bearingMag = 360 + bearingMag
  }

  let bearingMagString: string
  if (bearingMag >= 10 && bearingMag < 100) {
    bearingMagString = `0${bearingMag}`
  } else if (bearingMag < 10) {
    bearingMagString = `00${bearingMag}`
  } else {
    bearingMagString = bearingMag.toString()
  }

  return `${bearingMagString}°mag`
}
