import { useEffect, useMemo } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { MapState, MapStateProps } from '@/domain/states/MapState'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'

import { MapRoute } from '../components/MapRoute'
import { MapClickEvent } from '../interfaces/MapEvents'
import { FeatureInfoState, FeatureInfoStateProps } from '../states/FeatureInfoState'
import { MeasuringSegmentState, MeasuringSegmentStateProps } from '../states/MapMeasuringSegmentState'
import { MapSearchStateMutator } from '../states/MapSearchState'
import { ScreenElementsState, ScreenElementsStateProps } from '../states/ScreenElementsState'

function MapRoutePresenter() {
  const [routeState] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)
  const [mapState] = useGlobalState<MapState, MapStateProps>(InjectionTokens.MapState)
  const measuringSegmentState = useBehaviorSubject<MeasuringSegmentStateProps>(MeasuringSegmentState)
  const featureInfoState = useBehaviorSubject<FeatureInfoStateProps>(FeatureInfoState)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const waypoints = useMemo(() => routeState.activeRoute?.waypoints ?? [], [routeState.activeRoute])

  useEffect(() => {
    if (
      measuringSegmentState.active ||
      measuringSegmentState.measuring ||
      featureInfoState.active ||
      screenElementsState.toolPanel
    )
      return

    const mapClickSubscription = MapClickEvent.subscribe((position) => {
      MapSearchStateMutator.setSearchPosition(position)
    })

    return () => {
      mapClickSubscription.unsubscribe()
    }
  }, [MapClickEvent, waypoints, measuringSegmentState, featureInfoState, screenElementsState.toolPanel])

  if (!routeState.activeRoute) {
    return null
  }

  return <MapRoute waypoints={waypoints} nextWaypointSequence={mapState.nextWaypointSequence} />
}

export { MapRoutePresenter }
