import { useEffect, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppContainer } from '@presentation/App.styles'

import { InjectionTokens } from '@/controller/tokens'

import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { MapPresenter } from '@/presentation/modules/MapScreen/containers'

import { useGlobalState } from './hooks/useGlobalState'
import { BackdropGLobal } from './modules/Base/components/BackdropGlobal/BackdropGlobal'
import { BackToOldButton } from './modules/Base/components/general/BackToOldButton'
import { BetaNotice } from './modules/Base/components/general/BetaNotice'
import {
  BannerPresenter,
  DrawerPresenter,
  FeedbackCollectorPresenter,
  InfoPanelPresenter,
  MeteorologyControlPresenter,
  ModalPresenter,
  ToolbarPresenter
} from './modules/Base/containers'

function App() {
  const [routeState] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)
  useEffect(() => {
    const handleReloadPage = (event: any) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (routeState.activeRoute && !routeState.isSaved) window.addEventListener('beforeunload', handleReloadPage)

    return () => {
      window.removeEventListener('beforeunload', handleReloadPage)
    }
  }, [routeState])

  return (
    <AppContainer>
      <BrowserRouter>
        <DrawerPresenter />
      </BrowserRouter>
      <MapPresenter />
      <ToolbarPresenter />
      <InfoPanelPresenter />
      <MeteorologyControlPresenter />
      <ModalPresenter locale="global" />
      <BackdropGLobal />
      {/* <BannerPresenter /> TODO: habilitar o banner */}
      <FeedbackCollectorPresenter />
      <BackToOldButton />
      <BetaNotice />
    </AppContainer>
  )
}

export { App }
