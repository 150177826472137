import { useLayoutEffect } from 'react'

import GeoJSON from 'geojson'
import { Expression, LineLayout, LinePaint, MapLayerMouseEvent } from 'mapbox-gl'

import { useMapContext } from './MapContext'

export type LayerType = 'line' | 'fill'

interface MapGeoJsonLayerProps {
  types: LayerType[]
  id: string
  sourceId: string
  minZoom?: number
  maxZoom?: number
  layout?: LineLayout
  paint?: LinePaint

  beforeLayerId?: string

  borderColor?: string | Expression
  borderWidth?: number | Expression
  borderOpacity?: number | Expression
  fillColor?: string | Expression
  fillOpacity?: number | Expression

  onBorderClick?: (features: GeoJSON.Feature[]) => void
  onFillClick?: (features: GeoJSON.Feature[]) => void
}

function MapGeoJsonLayer(props: MapGeoJsonLayerProps): null {
  const {
    types,
    id,
    sourceId,
    minZoom = 0,
    maxZoom = 22,
    layout,
    paint,
    beforeLayerId,
    borderColor,
    borderWidth,
    borderOpacity,
    fillColor,
    fillOpacity,
    onBorderClick,
    onFillClick
  } = props

  const { map } = useMapContext()

  useLayoutEffect(() => {
    if (!map) return

    const borderClickListener = (event: MapLayerMouseEvent) => {
      onBorderClick?.(event.features ?? [])
    }

    const fillClickListener = (event: MapLayerMouseEvent) => {
      onFillClick?.(event.features ?? [])
    }

    const mouseEnterListener = () => {
      map.getCanvas().style.cursor = 'pointer'
    }

    const mouseLeaveListener = () => {
      map.getCanvas().style.cursor = ''
    }

    if (types.includes('line')) {
      map.addLayer(
        {
          id: `${id}-line`,
          type: 'line',
          source: sourceId,
          minzoom: minZoom ?? 0,
          maxzoom: maxZoom ?? 0,
          layout: {
            ...layout
          },
          paint: {
            'line-color': borderColor ?? '#000000',
            'line-width': borderWidth ?? 1,
            'line-opacity': borderOpacity ?? 1,
            ...paint
          }
        },
        beforeLayerId
      )

      if (onBorderClick) {
        map.on('click', `${id}-line`, borderClickListener)
        map.on('mouseenter', `${id}-line`, mouseEnterListener)
        map.on('mouseleave', `${id}-line`, mouseLeaveListener)
      }
    }

    if (types.includes('fill')) {
      map.addLayer(
        {
          id: `${id}-fill`,
          type: 'fill',
          source: sourceId,
          minzoom: minZoom,
          maxzoom: maxZoom,
          paint: {
            'fill-color': fillColor ?? '#000000',
            'fill-opacity': fillOpacity ?? 1
          }
        },
        beforeLayerId
      )

      if (onFillClick) {
        map.on('click', `${id}-fill`, fillClickListener)
        map.on('mouseenter', `${id}-fill`, mouseEnterListener)
        map.on('mouseleave', `${id}-fill`, mouseLeaveListener)
      }
    }

    return () => {
      if (types.includes('line')) map.removeLayer(`${id}-line`)
      if (types.includes('fill')) map.removeLayer(`${id}-fill`)
      if (onBorderClick) {
        map.off('click', `${id}-line`, borderClickListener)
        map.off('mouseenter', `${id}-line`, mouseEnterListener)
        map.off('mouseleave', `${id}-line`, mouseLeaveListener)
      }
      if (onFillClick) {
        map.off('click', `${id}-fill`, fillClickListener)
        map.off('mouseenter', `${id}-fill`, mouseEnterListener)
        map.off('mouseleave', `${id}-fill`, mouseLeaveListener)
      }
    }
  }, [map])

  return null
}

export { MapGeoJsonLayer }
