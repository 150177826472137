import { useMemo } from 'react'

import GeoJSON from 'geojson'
import mapboxgl, { Expression, MapLayerMouseEvent, Point } from 'mapbox-gl'

import { ADHP, Waypoint } from '@/domain/models'

import { MapSymbolLayer } from '../../MapView/MapSymbolLayer'
import { MapSymbolSource } from '../../MapView/MapSymbolSource'
import { makeGeoJsonADHPFeatureCollection } from '../GeojsonUtils'
import icons from '../icons'
import { iconStyleAd, iconStyleFocused, iconStyleHp, iconStyleUserWaypoint } from './MapADHP.styles'

interface MapADHPProps {
  adhps: Array<ADHP | Waypoint>
  showAerodromes: boolean
  showHelipads: boolean
  showUserWaypoints: boolean
  onlyPaved: boolean
  onlyNightOperation: boolean
  onlyATS: boolean
  onlyIFR: boolean
  minRunwayLength: number
  focusedWaypoint: string | null
  onSymbolClick: (waypointId: string, point: Point) => void
}

function MapADHP(props: MapADHPProps) {
  const filters: {
    [key: string]: Expression | Expression[]
  } = useMemo(() => {
    return {
      military: ['==', ['get', 'military'], true],
      notMilitary: ['==', ['get', 'military'], false],
      aerodrome: ['==', ['get', 'type'], 'AD'],
      helipad: ['==', ['get', 'type'], 'HP'],
      pavedRunway: ['==', ['get', 'pavedRunway'], true],
      unpavedRunway: ['==', ['get', 'pavedRunway'], false],
      nightOperation: ['==', ['get', 'nightOperation'], true],
      ifr: ['==', ['get', 'ifr'], true],
      ats: ['==', ['get', 'ats'], true],
      focused: ['==', ['get', 'id'], props.focusedWaypoint],
      notFocused: ['!=', ['get', 'id'], props.focusedWaypoint],
      minRunwayLength: ['>=', ['get', 'runwayLength'], props.minRunwayLength],
      userWaypoint: ['==', ['get', 'type'], 'user-waypoint']
    }
  }, [props.focusedWaypoint, props.minRunwayLength])

  const adhpSourceId = 'adhp-source'
  const layers = useMemo(
    () => [
      // User Waypoints
      // Focused
      {
        id: 'adhp-user-waypoints-focused',
        type: 'user-waypoint',
        aboveLayerID: 'adhp-stack',
        iconImage: icons['focused-user-waypoint'],
        layout: iconStyleUserWaypoint.layout,
        paint: { ...iconStyleUserWaypoint.paint, ...iconStyleFocused.paint },
        minZoomLevel: 0,
        filters: [filters.focused, props.showUserWaypoints, filters.userWaypoint],
        visibility: props.showUserWaypoints && props.focusedWaypoint ? 'visible' : 'none'
      },
      // Unfocused
      {
        id: 'adhp-user-waypoints',
        type: 'user-waypoint',
        aboveLayerID: 'adhp-stack',
        iconImage: icons['user-waypoint'],
        layout: iconStyleUserWaypoint.layout,
        paint: iconStyleUserWaypoint.paint,
        minZoomLevel: 7,
        filters: [props.showUserWaypoints, filters.userWaypoint, filters.notFocused],
        visibility: props.showUserWaypoints && !props.focusedWaypoint ? 'visible' : 'none'
      },
      // Helipads
      // Unfocused
      {
        id: 'adhp-helipads',
        type: 'HP',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['helipad'],
        layout: iconStyleHp.layout,
        paint: iconStyleHp.paint,
        minZoomLevel: 9,
        filters: [filters.helipad, filters.notFocused, filters.notMilitary],
        visibility: props.showHelipads && !props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-military-helipads',
        type: 'HP',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['military-helipad'],
        layout: iconStyleHp.layout,
        paint: iconStyleHp.paint,
        minZoomLevel: 9,
        filters: [filters.military, filters.helipad, filters.notFocused],
        visibility: props.showHelipads && !props.focusedWaypoint ? 'visible' : 'none'
      },
      // Focused
      {
        id: 'adhp-helipads-focused',
        type: 'HP',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['focused-helipad'],
        layout: iconStyleHp.layout,
        paint: { ...iconStyleHp.paint, ...iconStyleFocused.paint },
        minZoomLevel: 0,
        filters: [filters.helipad, filters.notMilitary, filters.focused],
        visibility: props.showHelipads && props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-military-helipads-focused',
        type: 'HP',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['focused-military-helipad'],
        layout: iconStyleHp.layout,
        paint: { ...iconStyleHp.paint, ...iconStyleFocused.paint },
        minZoomLevel: 9,
        filters: [filters.military, filters.helipad, filters.focused],
        visibility: props.showHelipads && props.focusedWaypoint ? 'visible' : 'none'
      },
      // ADHP
      // Unfocused
      {
        id: 'adhp-aerodromes-paved',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['aerodrome-paved'],
        layout: iconStyleAd.layout,
        paint: iconStyleAd.paint,
        minZoomLevel: 7,
        filters: [
          filters.aerodrome,
          filters.notMilitary,
          filters.pavedRunway,
          filters.notFocused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.showAerodromes && !props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-military-aerodromes-paved',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['military-aerodrome-paved'],
        layout: iconStyleAd.layout,
        paint: iconStyleAd.paint,
        minZoomLevel: 7,
        filters: [
          filters.military,
          filters.aerodrome,
          filters.pavedRunway,
          filters.notFocused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.showAerodromes && !props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-aerodromes-unpaved',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['aerodrome-unpaved'],
        layout: iconStyleAd.layout,
        paint: iconStyleAd.paint,
        minZoomLevel: 7,
        filters: [
          filters.aerodrome,
          filters.notMilitary,
          filters.unpavedRunway,
          filters.notFocused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.onlyPaved ? 'none' : props.showAerodromes && !props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-military-aerodromes-unpaved',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['military-aerodrome-unpaved'],
        layout: iconStyleAd.layout,
        paint: iconStyleAd.paint,
        minZoomLevel: 7,
        filters: [
          filters.military,
          filters.aerodrome,
          filters.unpavedRunway,
          filters.notFocused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.onlyPaved ? 'none' : props.showAerodromes && !props.focusedWaypoint ? 'visible' : 'none'
      },
      // Focused
      {
        id: 'adhp-aerodromes-paved-focused',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['focused-aerodrome-paved'],
        layout: iconStyleAd.layout,
        paint: { ...iconStyleAd.paint, ...iconStyleFocused.paint },
        minZoomLevel: 0,
        filters: [
          filters.aerodrome,
          filters.notMilitary,
          filters.pavedRunway,
          filters.focused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.showAerodromes && props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-military-aerodromes-paved-focused',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['focused-military-aerodrome-paved'],
        layout: iconStyleAd.layout,
        paint: { ...iconStyleAd.paint, ...iconStyleFocused.paint },
        minZoomLevel: 0,
        filters: [
          filters.military,
          filters.aerodrome,
          filters.pavedRunway,
          filters.focused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.showAerodromes && props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-aerodromes-unpaved-focused',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['focused-aerodrome-unpaved'],
        layout: iconStyleAd.layout,
        paint: { ...iconStyleAd.paint, ...iconStyleFocused.paint },
        minZoomLevel: 0,
        filters: [
          filters.aerodrome,
          filters.notMilitary,
          filters.unpavedRunway,
          filters.focused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.onlyPaved ? 'none' : props.showAerodromes && props.focusedWaypoint ? 'visible' : 'none'
      },
      {
        id: 'adhp-military-aerodromes-unpaved-focused',
        type: 'AD',
        beforeLayerId: 'adhp-stack',
        iconImage: icons['focused-military-aerodrome-unpaved'],
        layout: iconStyleAd.layout,
        paint: { ...iconStyleAd.paint, ...iconStyleFocused.paint },
        minZoomLevel: 0,
        filters: [
          filters.military,
          filters.aerodrome,
          filters.unpavedRunway,
          filters.focused,
          props.onlyNightOperation ? filters.nightOperation : true,
          props.onlyATS ? filters.ats : true,
          props.onlyIFR ? filters.ifr : true,
          props.minRunwayLength ? filters.minRunwayLength : true
        ],
        visibility: props.onlyPaved ? 'none' : props.showAerodromes && props.focusedWaypoint ? 'visible' : 'none'
      }
    ],
    [props]
  )
  const handleSymbolClick = (e: MapLayerMouseEvent) => {
    const pressedFeatureId = e.features[0].properties.id
    props.onSymbolClick(pressedFeatureId, e.point)
  }

  const geoJsonFromADHPs: GeoJSON.FeatureCollection = useMemo(() => {
    const featureCollection = makeGeoJsonADHPFeatureCollection(props.adhps)()
    return featureCollection
  }, [props.adhps])

  const layerComponents = useMemo(() => {
    return layers
      .filter((layer) => layer.visibility === 'visible')
      .map((layer) => {
        return (
          <MapSymbolLayer
            key={layer.id}
            id={layer.id}
            sourceId={adhpSourceId}
            imageSrc={layer.iconImage}
            layout={layer.layout as mapboxgl.SymbolLayout}
            paint={layer.paint as mapboxgl.SymbolPaint}
            filter={['all', ...layer.filters] as mapboxgl.Expression}
            minZoomLevel={layer.minZoomLevel}
            beforeLayerId={layer.beforeLayerId}
            onSymbolClick={handleSymbolClick}
          />
        )
      })
  }, [layers])

  return (
    <MapSymbolSource id={adhpSourceId} data={geoJsonFromADHPs}>
      {layerComponents}
    </MapSymbolSource>
  )
}

export { MapADHP }
