import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ScrollableContainer = styled.div`
  width: calc(100% + 10px);
  padding-right: 8px; // 9 (largura adicional) - 4 (largura da scrollbar)
  margin: 12px 0;

  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const SavedRouteList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
`

export const EmptyList = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  z-index: -1;
`

export const EmptyMessage = styled.p`
  width: 75%;
  text-align: center;
  ${({ theme }) => theme.fonts.infoLG}
  color: ${({ theme }) => theme.colors.secondaryL3};
`
