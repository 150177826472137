import { useCallback, useEffect, useMemo, useState } from 'react'

import { UniqueEntityID } from '@/domain/core'
import { Route } from '@/domain/models'
import { DeleteUserRouteUseCase, GetUserRoutesUseCase, SetActiveRouteUseCase } from '@/domain/useCases/Route'
import { GetSingleRouteUseCase } from '@/domain/useCases/Route/getSingleRoute'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { formatInUTC } from '@/utils/date'
import { ModalUtil } from '@/utils/modalUtil'
import { removeAccents } from '@/utils/string'
import { toastSubject, ToastUtil } from '@/utils/toastUtil'

import { LoadingStateMutator } from '../../Base/states/LoadingState'
import { MapImperativeInterface } from '../../MapScreen/interfaces/MapImperativeInterface'
import { ScreenElementsState, ScreenElementsStateProps } from '../../MapScreen/states/ScreenElementsState'
import { getFitBoundaries } from '../../MapScreen/utils/map'
import { SavedRoutesScreen } from '../components/SavedRoutesScreen'

function SavedRoutesScreenPresenter() {
  const { t, getDateFormat } = useI18n()

  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const [filterValue, setFilterValue] = useState('')
  const [userRoutes, setUserRoutes] = useState<Route[]>([])
  const [isGettingRoutes, setIsGettingRoutes] = useState(true)

  const routesSavedProps = useMemo(() => {
    if (userRoutes.length === 0) return []

    return userRoutes.map((route) => {
      const emptyInfo = '--'

      const totalDuration = route.totalDistance / route.groundSpeed
      const hours = Math.floor(totalDuration)
      const minutes = Math.floor((totalDuration - hours) * 60)

      const duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      const updatedAt = formatInUTC(route.updatedAt, getDateFormat())

      return {
        id: route.id,
        name: route.name,
        distance: route.totalDistance.toFixed(0),
        updatedAt,
        duration: route.groundSpeed ? duration : emptyInfo,
        groundSpeed: route.groundSpeed?.toFixed(0)
      }
    })
  }, [userRoutes])

  const filteredMemoizedRoutes = useMemo(
    () =>
      routesSavedProps.filter((routeProps) =>
        removeAccents(routeProps.name.toLowerCase()).includes(removeAccents(filterValue.toLowerCase()))
      ),
    [routesSavedProps, filterValue]
  )

  useEffect(() => {
    const bootstrap = async () => {
      await getUserRoutes()
      setIsGettingRoutes(false)
    }

    bootstrap()

    return () => {
      const currentToast = toastSubject.getValue()[0]
      if (currentToast) {
        currentToast.onFinish?.()
        ToastUtil.hide(currentToast.id)
      }
    }
  }, [])

  const setAndSortUserRoutes = useCallback((routes: Route[]) => {
    const sortedRoutes = routes.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
    setUserRoutes([...sortedRoutes])
  }, [])

  const getUserRoutes = useCallback(async () => {
    const userRoutes = await new GetUserRoutesUseCase().execute()
    if (userRoutes.isSuccess) setAndSortUserRoutes(userRoutes.getValue())
  }, [setAndSortUserRoutes])

  const handleSetActiveRoute = useCallback(
    async (routeId: UniqueEntityID) => {
      const routeToSetAsActive = await new GetSingleRouteUseCase().execute(routeId)
      if (!routeToSetAsActive) return

      const result = new SetActiveRouteUseCase().execute({ activeRoute: routeToSetAsActive.getValue(), isSaved: true })

      if (result.isSuccess) {
        sendMetrics('PLANNINGAPP_DRAWER_SAVED-ROUTE_SELECTED')
        ToastUtil.send({
          label: t('TOAST_ROUTE_LOAD_SUCCESS'),
          variant: 'primary',
          dismissible: true
        })
        const boundaries = getFitBoundaries(routeToSetAsActive.getValue())

        const padding: [number, number, number, number] = [
          40 + screenElementsState.boundsDefaultPadding.top,
          75 + screenElementsState.boundsDefaultPadding.right,
          10 + screenElementsState.boundsDefaultPadding.bottom,
          75 + screenElementsState.boundsDefaultPadding.left
        ]
        MapImperativeInterface.flyToBounds(boundaries, padding, 500)
      } else
        ToastUtil.send({
          label: t('TOAST_ROUTE_LOAD_ERROR'),
          variant: 'error',
          dismissible: true
        })
    },
    [screenElementsState.boundsDefaultPadding]
  )

  const handleDeleteUserRoute = useCallback(
    async (routeId: string) => {
      const deleteRoute = async () => {
        LoadingStateMutator.setIsLoading(true)
        const routeToDelete = userRoutes.find((route) => route.id.toString() === routeId)
        const routeToDeleteIndex = userRoutes.findIndex((route) => route.id.toString() === routeId)

        setAndSortUserRoutes(userRoutes.filter((route) => route.id.toString() !== routeId))
        sendMetrics('PLANNINGAPP_DRAWER_SAVED-ROUTE_DELETED')
        ToastUtil.send({
          label: t('TOAST_ROUTE_DELETE_SUCCESS'),
          variant: 'error',
          dismissible: true,
          timeDelay: 5000,
          actionLabel: t('TOAST_ROUTE_DELETE_UNDO'),
          onAction: () => {
            setUserRoutes((prev) => {
              prev.splice(routeToDeleteIndex, 0, routeToDelete)
              return [...prev]
            })

            return false
          },
          onFinish: async () => {
            const userRoutes = await new DeleteUserRouteUseCase().execute(routeToDelete.id.toString())
            if (userRoutes.isSuccess) setAndSortUserRoutes(userRoutes.getValue())
            else
              ToastUtil.send({
                label: t('TOAST_ROUTE_DELETE_ERROR'),
                variant: 'error'
              })
          }
        })

        LoadingStateMutator.setIsLoading(false)
      }
      ModalUtil.drawer.show({
        title: t('MODAL_DELETE-ROUTE_TITLE'),
        positiveColor: 'primary',
        positiveLabel: t('MODAL_DELETE-ROUTE_BUTTON_CONFIRM'),
        negativeLabel: t('MODAL_DELETE-ROUTE_BUTTON_CANCEL'),
        onPositive: deleteRoute, // TODO: Fazer com que essa chamada da função espere com o await, fazendo com que o loading do botão apareça
        onNegative: () => LoadingStateMutator.setIsLoading(false),
        onClose: () => LoadingStateMutator.setIsLoading(false)
      })
      return
    },
    [userRoutes]
  )

  return (
    <SavedRoutesScreen
      routes={filteredMemoizedRoutes}
      shouldShowLoading={isGettingRoutes}
      filterValue={filterValue}
      onFilterChange={setFilterValue}
      onPress={handleSetActiveRoute}
      onDelete={handleDeleteUserRoute}
    />
  )
}

export { SavedRoutesScreenPresenter }
