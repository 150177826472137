export enum appZIndex {
  BANNER_FOOTER = 50,
  CHAT_CLOSED = 100,
  METEOROLOGY_CONTROL = 100,
  MODAL = 100,
  TOOLBAR = 200,
  TOOL_PANEL = 200,
  INFO_PANEL = 300,
  DRAWER = 400,
  SEARCH_BOX = 450,
  MAP_SEARCH = 800,
  WAYPOINT_INFO = 800,
  TOOLTIP = 800,
  CHAT_OPEN = 800,
  BANNER_DESKTOP = 1000,
  BANNER_MOBILE = 1000
}
