import { useCallback, useEffect, useMemo, useState } from 'react'

import { Waypoint } from '@/domain/models'
import { MapPosition } from '@/domain/protocols/MapPosition'
import { GetProximityWaypointsUseCase } from '@/domain/useCases/Map'
import { AddWaypointToRouteUseCase, OverrideWaypointOnRoute } from '@/domain/useCases/Route'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { openDrawer } from '@/presentation/router/DrawerRouter'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { SearchList } from '../../Base/components/SearchList'
import { useMapContext } from '../components/MapView/MapContext'
import { MapImperativeInterface } from '../interfaces/MapImperativeInterface'
import { MapSearchState, MapSearchStateMutator, MapSearchStateProps } from '../states/MapSearchState'
import { ScreenElementsState, ScreenElementsStateProps } from '../states/ScreenElementsState'
import { getFitBoundaries } from '../utils/map'
import { MapSearchContainer, Overlay } from './Map.styles'

function MapSearchPresenter() {
  const { map } = useMapContext()

  const { innerWidth: width, innerHeight: height } = window
  const searchListMaxHeight = 300
  const searchListWidth = 336

  const mapSearchState = useBehaviorSubject<MapSearchStateProps>(MapSearchState)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const [searchOnMapResultSet, setSearchOnMapResultSet] = useState<Waypoint[]>([])
  const [searchOnMapScreenPos, setSearchOnMapScreenPos] = useState<MapPosition['position']>({ x: 0, y: 0 })

  const searchListPosition = useMemo(() => {
    // tanto largura quanto altura têm um gap de 20px
    const screenWidthLimit = width - searchListWidth - 44 - 20 // largura da toolbar + distância de margem da tela
    const screenHeightLimit = height - searchListMaxHeight - 20
    return {
      top: searchOnMapScreenPos.y,
      left: searchOnMapScreenPos.x,
      translateX: searchOnMapScreenPos.x > screenWidthLimit ? '-100%' : '0',
      translateY: searchOnMapScreenPos.y > screenHeightLimit ? '-100%' : '0'
    }
  }, [searchOnMapScreenPos])

  useEffect(() => {
    if (!map) return
    if (mapSearchState.searchPosition) {
      const mapPosition = mapSearchState.searchPosition
      handleSearchOnMap(mapPosition)
      map?.dragPan.disable()
    } else {
      map?.dragPan.enable()
    }
  }, [mapSearchState, map])

  const handleSearchOnMap = useCallback(
    (mapPosition: MapPosition) => {
      const resultSet = new GetProximityWaypointsUseCase().execute(mapPosition.coordinates)

      if (resultSet.isSuccess) {
        setSearchOnMapResultSet(resultSet.getValue())
        setSearchOnMapScreenPos(mapPosition.position)
      }
    },
    [setSearchOnMapResultSet, setSearchOnMapScreenPos]
  )

  const handleAddWaypointToRoute = useCallback(
    (item: Waypoint): void => {
      if (mapSearchState.searchOverride !== null) {
        new OverrideWaypointOnRoute().execute({ waypoint: item, index: mapSearchState.searchOverride })
      } else {
        const updatedRoute = new AddWaypointToRouteUseCase().execute(item)
        if (updatedRoute.isFailure) return

        sendMetrics('PLANNINGAPP_MAP_POINT_CLICKED')

        const padding: [number, number, number, number] = [
          40 + screenElementsState.boundsDefaultPadding.top,
          75 + screenElementsState.boundsDefaultPadding.right,
          10 + screenElementsState.boundsDefaultPadding.bottom,
          75 + screenElementsState.boundsDefaultPadding.left
        ]

        if (updatedRoute.getValue().waypoints.length === 1) {
          openDrawer(RouterPaths.ROUTES_PANEL)
        } else {
          const boundaries = getFitBoundaries(updatedRoute.getValue())
          MapImperativeInterface.flyToBounds(boundaries, padding, 500)
        }
      }

      handleCloseSearchOnMap()
    },
    [mapSearchState.searchOverride]
  )

  const handleCloseSearchOnMap = useCallback(() => {
    MapSearchStateMutator.setSearchPositionAndOverride(null, null)
    setSearchOnMapResultSet([])
  }, [])

  // Listen to ESC key press to close results
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseSearchOnMap()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  if (searchOnMapResultSet.length === 0) {
    return null
  }

  return (
    <Overlay onClick={handleCloseSearchOnMap}>
      <MapSearchContainer width={searchListWidth} screenPosition={searchListPosition}>
        <SearchList
          waypoints={searchOnMapResultSet}
          maxHeight={searchListMaxHeight}
          onWaypointClick={handleAddWaypointToRoute}
        />
      </MapSearchContainer>
    </Overlay>
  )
}

export { MapSearchPresenter }
