import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle
} from 'react-beautiful-dnd'

import { Assets } from '@nexds/web'
import moment from 'moment'
import { useTheme } from 'styled-components'

import { CoordWaypoint, Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { useI18n } from '@/presentation/hooks/useI18n'
import { MapImperativeInterface } from '@/presentation/modules/MapScreen/interfaces/MapImperativeInterface'
import { millisecondsToHourMinute } from '@/presentation/utils/route'
import { convertDecimalDegreesToDMS } from '@/utils/coordinates'
import { ToastUtil } from '@/utils/toastUtil'

import {
  Clickable,
  Container,
  Content,
  ContentInfoWrapper,
  DistanceField,
  DistanceFieldInfo,
  WaypointInfo,
  Coord,
  Name,
  NameInput,
  ActionButtonsWrapper,
  ActionButton,
  Divider
} from './WaypointItem.styles'

type DistanceInfo = {
  distanceAccumulated: number
  timeAccumulated: number
  distance: number
  bearing: string
  time: number
}

interface WaypointItemProps {
  waypoint: Waypoint
  index: number
  distanceInfo: DistanceInfo
  onEditName: (waypoint: Waypoint, name: string) => void
  onDelete: () => void
}

function WaypointItem(props: WaypointItemProps) {
  const { waypoint, index, distanceInfo, onEditName, onDelete } = props

  const { t } = useI18n()
  const theme = useTheme()

  const DMSCoordinates = useMemo(() => {
    return convertDecimalDegreesToDMS(waypoint.coordinates.latitude, waypoint.coordinates.longitude)
  }, [waypoint.coordinates])

  const waypointName = useMemo(() => {
    const coordWaypointName = waypoint.customName ? waypoint.customName : t('WAYPOINT_TYPE_COORD-WAYPOINT')
    return waypoint instanceof CoordWaypoint ? coordWaypointName : waypoint.getDisplayName()
  }, [waypoint])

  const [nameInputValue, setNameInputValue] = useState(waypointName)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const nameInputRef = useRef(null)
  const confirmButtonRef = useRef(null)

  useEffect(() => {
    if (isInputFocused) {
      nameInputRef.current?.select()
    }
  }, [isInputFocused])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !nameInputRef.current?.contains(event.target as Node) &&
        !confirmButtonRef.current?.contains(event.target as Node) &&
        isInputFocused
      ) {
        undoEditName()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isInputFocused])

  const handleEditName = useCallback(() => {
    if (waypointName !== nameInputValue) onEditName(waypoint, nameInputValue)
    setIsInputFocused(false)
  }, [waypointName, nameInputValue])

  const undoEditName = useCallback(() => {
    setNameInputValue(waypointName)
    setIsInputFocused(false)
  }, [waypointName])

  const handleShowInfo = (infoCode: string) => {
    window.open(`https://aisweb.decea.gov.br/?i=aerodromos&codigo=${infoCode}`)
  }

  const handleLocateWaypoint = (coordinates: Coordinates) => {
    MapImperativeInterface.flyToCoordinates(coordinates, 8, 2000)
  }

  const handleCopyCoordinates = async () => {
    try {
      navigator.clipboard
        .writeText(DMSCoordinates)
        .then(() => {
          ToastUtil.send({
            label: t('TOAST_COORDINATES_COPY_SUCCESS'),
            variant: 'primary'
          })
        })
        .catch(() => {
          ToastUtil.send({
            label: t('TOAST_COORDINATES_COPY_ERROR'),
            variant: 'error'
          })
        })
    } catch (error) {
      ToastUtil.send({
        label: t('TOAST_COORDINATES_COPY_ERROR'),
        variant: 'error'
      })
    }
  }

  const getStyle = useCallback((style: DraggingStyle | NotDraggingStyle, snapshot: DraggableStateSnapshot) => {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      transitionDuration: `0.001s`
    }
  }, [])

  return (
    <Draggable draggableId={waypoint.id.toString()} index={index}>
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          {distanceInfo && (
            <DistanceField show={!snapshot.isDragging}>
              <DistanceFieldInfo align="left">{distanceInfo.bearing}</DistanceFieldInfo>

              <DistanceFieldInfo align="center">{`${distanceInfo.distance.toFixed(1)}nm`}</DistanceFieldInfo>
              <DistanceFieldInfo isTime align="center">
                {distanceInfo.time
                  ? millisecondsToHourMinute(moment.duration(distanceInfo.time, 'h').as('milliseconds'))
                  : '--'}
              </DistanceFieldInfo>
              <Divider />
              <DistanceFieldInfo
                align="center"
                isAccumulated
              >{`${distanceInfo.distanceAccumulated.toFixed(1)}nm`}</DistanceFieldInfo>

              <DistanceFieldInfo align="right" isTime isAccumulated>
                {distanceInfo.time
                  ? millisecondsToHourMinute(moment.duration(distanceInfo.timeAccumulated, 'h').as('milliseconds'))
                  : '--'}
              </DistanceFieldInfo>
            </DistanceField>
          )}
          <Content onClick={() => handleLocateWaypoint(waypoint.coordinates)}>
            <ContentInfoWrapper>
              <WaypointInfo>
                {isInputFocused && waypoint instanceof CoordWaypoint ? (
                  <>
                    <NameInput
                      ref={nameInputRef}
                      value={nameInputValue}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') undoEditName()
                        if (e.key === 'Enter') handleEditName()
                      }}
                      onChange={(e) => setNameInputValue(e.target.value)}
                    />
                    <Clickable
                      ref={confirmButtonRef}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleEditName()
                      }}
                    >
                      <Assets.Check color={theme.colors.neutralL5} size="sm" />
                    </Clickable>
                  </>
                ) : waypoint instanceof CoordWaypoint ? (
                  <>
                    <Name>{waypointName}</Name>
                    <Clickable
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsInputFocused(true)
                      }}
                    >
                      <Assets.Edit color={theme.colors.neutralL5} size="sm" />
                    </Clickable>
                  </>
                ) : (
                  <Name>{waypointName}</Name>
                )}
              </WaypointInfo>
              <WaypointInfo hideActionButton>
                <Coord>{DMSCoordinates}</Coord>
                <Clickable
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCopyCoordinates()
                  }}
                >
                  <Assets.Copy color={theme.colors.neutralL5} size="sm" />
                </Clickable>
              </WaypointInfo>
            </ContentInfoWrapper>
            <ActionButtonsWrapper>
              {waypoint.waypointType?.codeName === 'ADHP' && (
                <ActionButton onClick={() => (waypoint.code ? handleShowInfo(waypoint.code) : undefined)}>
                  <Assets.Info color={theme.colors.neutralL5} size={'sm'} />
                </ActionButton>
              )}
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete()
                }}
              >
                <Assets.Close2 color={theme.colors.secondaryL3} size={'sm'} />
              </ActionButton>
            </ActionButtonsWrapper>
          </Content>
        </Container>
      )}
    </Draggable>
  )
}

export { WaypointItem }
