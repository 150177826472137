import { injectable } from 'inversify'
import { jsonDateParser } from 'json-date-parser'

import { Route } from '../models/Route/Route'
import { SerializableState } from './SerializableState'

export interface RouteStateProps {
  activeRoute: Route
  isSaved: boolean
}

const INITIAL_STATE: RouteStateProps = {
  isSaved: false,
  activeRoute: null
}

@injectable()
export class RouteState extends SerializableState<RouteStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setActiveRoute(route: Route, isSaved = false) {
    this._updateState({
      activeRoute: route,
      isSaved
    })
  }

  setIsSaved(isSaved: boolean) {
    this._updateStateProp('isSaved', isSaved)
  }

  getKey(): string {
    return 'route'
  }

  toString(): string {
    const snapshot = this.getStateSnapshot()

    return JSON.stringify({
      activeRoute: snapshot.activeRoute?.toPojo() ?? null,
      isSaved: snapshot.isSaved
    })
  }

  fromString(state: string): void {
    const parsedState = JSON.parse(state, jsonDateParser)

    this._updateState({
      activeRoute: Route.fromPojo(parsedState.activeRoute),
      isSaved: parsedState.isSaved
    })
  }
}
