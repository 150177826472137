import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { BetaScreenPresenter } from '../modules/BetaScreen/containers/BetaScreenPresenter'
import { ChartsAndDataScreenPresenter } from '../modules/ChartsAndDataScreen/containers/ChartsAndDataPresenter'
import { ChartScreenPresenter } from '../modules/ChartScreen/ChartScreenPresenter'
import { ConfigurationScreenPresenter } from '../modules/ConfigurationScreen/containers/ConfigurationScreenPresenter'
import { MarketScreenPresenter } from '../modules/MarketScreen/containers/MarketScreenPresenter'
import { MobileAppScreenPresenter } from '../modules/MobileAppScreen/containers/MobileAppScreenPresenter'
import { NewsScreenPresenter } from '../modules/NewsScreen/containers/NewsScreenPresenter'
import { RoutesPanelScreenPresenter } from '../modules/RoutesPanelScreen/containers/RoutesPanelScreenPresesnter'
import { SavedRoutesScreenPresenter } from '../modules/SavedRoutesScreen/containers/SavedRoutesScreenPresenter'
import { UserWaypointsScreenPresenter } from '../modules/UserWaypointsScreen/containers/UserWaypointsScreenPresenter'
import { RouterPaths } from './RouterPathsMapper'

interface ChangeRouteEventDetail {
  screen: RouterPaths
}

type RouteEvent = CustomEvent<ChangeRouteEventDetail>

export const openDrawer = (screen: RouterPaths) => {
  const evento = new CustomEvent<ChangeRouteEventDetail>('change-route', {
    detail: {
      screen
    }
  })

  document.dispatchEvent(evento)
}

export const closeDrawer = () => {
  const evento = new CustomEvent<ChangeRouteEventDetail>('change-route', {
    detail: {
      screen: RouterPaths.MAP
    }
  })

  document.dispatchEvent(evento)
}

function DrawerRouter() {
  const navigate = useNavigate()

  useEffect(() => {
    const changeRoute = (e: RouteEvent) => {
      navigate(e.detail.screen)
    }
    document.addEventListener('change-route', (e) => changeRoute(e as RouteEvent))

    return () => {
      document.removeEventListener('change-route', (e) => changeRoute(e as RouteEvent))
    }
  }, [])

  return (
    <Routes>
      <Route path="/" element={null} />
      <Route path={RouterPaths.MAP} element={null} />
      <Route path={RouterPaths.ROUTES_PANEL} element={<RoutesPanelScreenPresenter />} />
      <Route path={RouterPaths.SAVED_ROUTES} element={<SavedRoutesScreenPresenter />} />
      <Route path={RouterPaths.PROCEDURE_CHARTS} element={<ChartScreenPresenter />} />
      <Route path={RouterPaths.MARKET} element={<MarketScreenPresenter />} />
      <Route path={RouterPaths.BETA} element={<BetaScreenPresenter />} />
      <Route path={RouterPaths.MOBILE} element={<MobileAppScreenPresenter />} />
      <Route path={RouterPaths.NEWS} element={<NewsScreenPresenter />} />
      <Route path={RouterPaths.SETTINGS} element={<ConfigurationScreenPresenter />}>
        <Route path={RouterPaths.SETTINGS__USER_WAYPOINTS} element={<UserWaypointsScreenPresenter />}>
          <Route path={RouterPaths.SETTINGS__USER_WAYPOINTS__CREATE} element={<UserWaypointsScreenPresenter />} />
          <Route path={RouterPaths.SETTINGS__USER_WAYPOINTS__EDIT} element={<UserWaypointsScreenPresenter />} />
        </Route>
        <Route path={RouterPaths.SETTINGS__DATA_AND_CHARTS} element={<ChartsAndDataScreenPresenter />} />
      </Route>
    </Routes>
  )
}

export { DrawerRouter }
